// 事件中心
import $bus from '@/utils/eventsBus'

// 学生权限
export function recvMsg_10000(data) {
  const AUTH = data.data.value
  // console.log(index);
  $bus.$emit('kidsAuthority', AUTH)
}

// 翻页,跳页
export function recvMsg_10001(data) {
  const index = data.data
  $bus.$emit('changePageIndex', index)
}

// 切换课件
export function recvMsg_10002(data) {
  // console.log(data);
  const index = data.data.value
  const calendarEventId = data.roomId
  // console.log("课件lesso", index);
  $bus.$emit('kidsSocketSwitchLesson', {
    index,
    calendarEventId
  })
}
// 切换课程目标和画板
export function recvMsg_10003(data) {
  const index = data.data.value
  $bus.$emit('switchPageType', index)
}

// 画板页面交互
export function recvMsg_1000401(data) {
  const obj = data.data
  $bus.$emit('showDrawLine', obj)
}
export function recvMsg_1000402(data) {
  const obj = data.data.value
  $bus.$emit('showDrawRect', obj)
}
export function recvMsg_1000403(data) {
  const obj = data.data.value
  $bus.$emit('showDrawCircle', obj)
}
export function recvMsg_1000404(data) {
  const obj = data.data.value
  $bus.$emit('showDrawText', obj)
}
export function recvMsg_1000405(data) {
  const obj = data.data.value
  $bus.$emit('showDrawTriangle', obj)
}

export function recvMsg_1000406(data) {
  const str = data.data.value
  $bus.$emit('showControlCanvas', str)
}

export function recvMsg_1000407(data) {
  const str = data.data.value
  $bus.$emit('showGlobalCanvas', str)
}

export function recvMsg_1000408(data) {
  const obj = data.data.value
  $bus.$emit('showEraser', obj)
}

// 点击按钮时学生端也点击样式
export function recvMsg_1000409(data) {
  const obj = data.data.value
  $bus.$emit('controlToolbarStatus', obj)
}

/**
 *
 * toolBar Socket 交互 开始
 */
export function recvMsg_1000501(data) {
  const value = data.data.value
  $bus.$emit('toolBarMouseClick', value)
}
export function recvMsg_1000502(data) {
  const value = data.data.value
  $bus.$emit('toolBarDrawLine', value)
}
export function recvMsg_1000503(data) {
  const value = data.data.value
  $bus.$emit('toolBarInsertWords', value)
}
export function recvMsg_1000504(data) {
  const value = data.data.value
  $bus.$emit('toolBarChooseShape', value)
}
export function recvMsg_1000505(data) {
  const value = data.data.value
  $bus.$emit('toolBarUseEraser', value)
}
export function recvMsg_1000506(data) {
  const value = data.data.value
  $bus.$emit('toolBarControlAction', value)
}
export function recvMsg_1000507(data) {
  const value = data.data.value
  $bus.$emit('toolBarOpenChooseDrawColor', value)
}
export function recvMsg_1000508(data) {
  const value = data.data.value
  $bus.$emit('toolBarChooseDrawColor', value)
}
export function recvMsg_1000509(data) {
  const value = data.data.value
  $bus.$emit('toolBarChooseLineWidth', value)
}
export function recvMsg_1000510(data) {
  const value = data.data.value
  $bus.$emit('toolBarOpenFontSize', value)
}
export function recvMsg_1000511(data) {
  const value = data.data.value
  $bus.$emit('toolBarChooseFontSize', value)
}
export function recvMsg_1000512(data) {
  const value = data.data.value
  $bus.$emit('toolBarChooseShapeType', value)
}
export function recvMsg_1000513(data) {
  const value = data.data.value
  $bus.$emit('teacherMouseMove', value)
}
export function recvMsg_1000514(data) {
  const value = data.data
  $bus.$emit('mouseOffsetXY', value)
}

/**
 *
 * toolBar Socket 交互  结束
 */

// 视频区星星
export function recvMsg_10005(data) {
  const value = data.data.value
  $bus.$emit('interactionStars', value)
}

// 下课

// speaking页面的评分
export function recvMsg_11001(data) {
  const value = data.data.value
  $bus.$emit('getTeacherRate', value)
}
export function recvMsg_10006(data) {
  const value = data.data.value
  $bus.$emit('endClassSocket', value)
}

// to do list
// start-stop组件内点击事件  完成 1
export function recvMsg_20000_StartStop(data) {
  const value = data.data.value
  console.log(value)
  $bus.$emit('clickStartStop', value)
}
//hover
export function recvMsg_21000_StartStop(data) {
  const value = data.data.value
  console.log(value)
  $bus.$emit('hoverStartStop', value)
}

// story-page组件内点击事件  完成 2
export function recvMsg_20001_StoryPage(data) {
  const value = data.data.value
  console.log(value)
  $bus.$emit('clickStartStop', value)
}
// story-page组件内点击事件
export function recvMsg_2000101_StoryPage(data) {
  const value = data.data.value
  console.log(value)
  $bus.$emit('hoverPlay', value)
}

// RecognitionPage组件内点击事件  完成 3
export function recvMsg_20002_RecognitionPage(data) {
  const value = data.data.value
  console.log(value)
  $bus.$emit('clickRecognitionPage', value)
}
// hover
export function recvMsg_21002_RecognitionPage(data) {
  const value = data.data.value
  console.log(value)
  $bus.$emit('hoverRecognitionPage', value)
}

// RecognitionPage组件内button点击事件
// export function recvMsg_2000201_RecognitionPage(data) {
//   const value = data.data.value
//   console.log(value);
//   $bus.$emit('clickRecognitionPageButton', value);
// }

// WriteStrokePage组件内点击事件  完成 4 一共三个事件
export function recvMsg_2000301_WriteStrokePage(data) {
  const value = data.data.value
  $bus.$emit('startDrawStroke', value)
}
export function recvMsg_2000302_WriteStrokePage(data) {
  const value = data.data.value
  $bus.$emit('drawSingleStroke', value)
}
export function recvMsg_2000303_WriteStrokePage(data) {
  const value = data.data.value
  $bus.$emit('backOrigin', value)
}

// export function recvMsg_2000401_SyllabusPageA(data) {
//   const value = data.data.value
//   $bus.$emit('hoverWords', value);
// }// hover 不需要

// export function recvMsg_2000402_SyllabusPageA(data) {
//   const value = data.data.value
//   $bus.$emit('playWords', value);
// }

// SyllabusPage组件内点击事件  完成5
export function recvMsg_20004_SyllabusPage(data) {
  const value = data.data.value
  $bus.$emit('hoverSyllabusPage', value)
}

// PracticePage组件内点击事件  完成 6
export function recvMsg_2000501_PracticePage(data) {
  const value = data.data.value
  $bus.$emit('clickGameSideBtn', value)
}
export function recvMsg_2000502_PracticePage(data) {
  const value = data.data.value
  $bus.$emit('clickChooseImg', value)
}

// writeHanziPage组件内点击事件  完成 7
export function recvMsg_2000601_WriteHanziPage(data) {
  const value = data.data.value
  $bus.$emit('clickDrawBtn', value)
}
export function recvMsg_2000602_WriteHanziPage(data) {
  const value = data.data.value
  $bus.$emit('writeHanziSteps', value)
}
export function recvMsg_2000603_WriteHanziPage(data) {
  const value = data.data.value
  $bus.$emit('clickTips', value)
}
export function recvMsg_2000604_WriteHanziPage(data) {
  const value = data.data.value
  $bus.$emit('clickInit', value)
}

// WordCardsPage组件内点击事件  完成 8
export function recvMsg_20007_WordCardsPage(data) {
  const value = data.data.value
  $bus.$emit('clickSingleCard', value)
}
export function recvMsg_2000701_WordCardsGroupPage(data) {
  const value = data.data.value
  $bus.$emit('clickGroupCardsMenu', value)
}

// learnReview组件内点击事件  完成 9
export function recvMsg_2000801_LearnReview(data) {
  const value = data.data.value
  $bus.$emit('deleteWordsScoket', value)
}
export function recvMsg_2000802_LearnReview(data) {
  const value = data.data.value
  $bus.$emit('deleteImgScoket', value)
}

// RocketGame组件内点击事件  完成 10
export function recvMsg_20009_RocketGame(data) {
  const value = data.data.value
  $bus.$emit('clickRocketGame', value)
}

// DragGame组件内点击事件  完成 11
export function recvMsg_2001001_DragGame(data) {
  const value = data.data.value
  $bus.$emit('clickRandomBtn', value)
}
export function recvMsg_2001002_DragGame(data) {
  const value = data.data.value
  $bus.$emit('dragEnvelope', value)
}
export function recvMsg_2001003_DragGame(data) {
  const value = data.data.value
  $bus.$emit('checkDrag', value)
}

export function recvMsg_2001004_DragGame(data) {
  const value = data.data.value
  $bus.$emit('shuffleArray', value)
}

// MoveGame组件内点击事件  完成 12
export function recvMsg_20011_MoveGame(data) {
  const value = data.data.value
  $bus.$emit('clickMoveGame', value)
}

// FindStrokeGame组件内点击事件  完成 13
export function recvMsg_20012_FindStrokeGame(data) {
  const value = data.data.value
  $bus.$emit('clickRightStroke', value)
}

// FindStrokeTrainGame组件内点击事件  完成 14
export function recvMsg_20013_FindStrokeTrainGame(data) {
  const value = data.data.value
  $bus.$emit('clickRightStrokeArea', value)
}

// SpeechBubble组件内点击事件  完成 15
export function recvMsg_20014_SpeechBubble(data) {
  const value = data.data.value
  $bus.$emit('clickSpeechBubble', value)
}

// FindStrokePathGame组件事件
export function recvMsg_2001501_FindStrokePathGame(data) {
  const value = data.data.value
  $bus.$emit('clickChooseAnimal', value)
}
export function recvMsg_2001502_FindStrokePathGame(data) {
  const value = data.data.value
  $bus.$emit('clickUp', value)
}
export function recvMsg_2001503_FindStrokePathGame(data) {
  const value = data.data.value
  $bus.$emit('clickDown', value)
}
export function recvMsg_2001504_FindStrokePathGame(data) {
  const value = data.data.value
  $bus.$emit('clickLeft', value)
}
export function recvMsg_2001505_FindStrokePathGame(data) {
  const value = data.data.value
  $bus.$emit('clickRight', value)
}

// RangMapPage组件滚轮事件
export function recvMsg_20016_RankMapPage(data) {
  const value = data.data.value
  $bus.$emit('scrollMouse', value)
}

// RangMapPage组件滚轮事件
export function recvMsg_20017_SyllabusPage(data) {
  const value = data.data.value
  $bus.$emit('scrollMouseX', value)
}

// NumberClickGame组件事件
export function recvMsg_20018_NumberClickGame(data) {
  const value = data.data.value
  $bus.$emit('NumberClick', value)
}

// NumberClickGame组件事件
export function recvMsg_20019_RecognitionNumberGame(data) {
  const value = data.data.value
  $bus.$emit('RecognitionNumberClick', value)
}

// PhoneNumberGame组件事件
export function recvMsg_2002001_RecognitionNumberGame(data) {
  const value = data.data.value
  $bus.$emit('addNumber', value)
}
export function recvMsg_2002002_RecognitionNumberGame(data) {
  const value = data.data.value
  $bus.$emit('clearNumber', value)
}
export function recvMsg_2002003_RecognitionNumberGame(data) {
  const value = data.data.value
  $bus.$emit('callPhone', value)
}

// RecognitionPinyinGame组件事件
export function recvMsg_20021_RecognitionPinyinGame(data) {
  const value = data.data.value
  $bus.$emit('RecognitionPinyinClick', value)
}
export function recvMsg_2002101_RecognitionPinyinGame(data) {
  const value = data.data.value
  $bus.$emit('RecognitionPinyinAudio', value)
}

// RobotJumpGame组件事件
export function recvMsg_20022_RobotJumpGame(data) {
  const value = data.data.value
  $bus.$emit('robotJump', value)
}
export function recvMsg_2002201_RobotJumpGame(data) {
  const value = data.data.value
  $bus.$emit('robotJumpAudio', value)
}

// pinyinSummary组件事件
export function recvMsg_2002301_PinyinSummary(data) {
  const value = data.data.value
  $bus.$emit('clickPinyinItem', value)
}
export function recvMsg_2002302_PinyinSummary(data) {
  const value = data.data.value
  $bus.$emit('clickToneItem', value)
}

// WriteStrokeReview组件事件
export function recvMsg_2002401_WriteStrokeReview(data) {
  const value = data.data.value
  $bus.$emit('startReviewStroke', value)
}
export function recvMsg_2002402_WriteStrokeReview(data) {
  const value = data.data.value
  $bus.$emit('drawSingleStrokeReview', value)
}

// FindNumberGame组件事件
export function recvMsg_2002501_FindNumberGame(data) {
  const value = data.data.value
  $bus.$emit('clickGameWheel', value)
}
export function recvMsg_2002502_FindNumberGame(data) {
  const value = data.data.value
  $bus.$emit('clickGameNumber', value)
}

// WritePinyinPage组件事件
export function recvMsg_2002601_WritePinyinPage(data) {
  const value = data.data.value
  $bus.$emit('startDrawPinyin', value)
}
export function recvMsg_2002602_WritePinyinPage(data) {
  const value = data.data.value
  $bus.$emit('nextPinyinDraw', value)
}
export function recvMsg_2002603_WritePinyinPage(data) {
  const value = data.data.value
  $bus.$emit('showTipsImg', value)
}
export function recvMsg_2002604_WritePinyinPage(data) {
  const value = data.data.value
  $bus.$emit('menuClick', value)
}

// FindElementByPicGame组件事件
export function recvMsg_20027_FindElementByPicGame(data) {
  const value = data.data.value
  $bus.$emit('chooseElementToCheck', value)
}
export function recvMsg_2002701_FindElementByPicGame(data) {
  const value = data.data.value
  $bus.$emit('chooseElementAudio', value)
}

// WriteHanziReviewPage组件事件
export function recvMsg_2002801_WriteHanziReviewPage(data) {
  const value = data.data.value
  $bus.$emit('clickDrawBtnReview', value)
}
export function recvMsg_2002802_WriteHanziReviewPage(data) {
  const value = data.data.value
  $bus.$emit('writeHanziStepsReview', value)
}
export function recvMsg_2002803_WriteHanziReviewPage(data) {
  const value = data.data.value
  $bus.$emit('clickTipsReview', value)
}
export function recvMsg_2002804_WriteHanziReviewPage(data) {
  const value = data.data.value
  $bus.$emit('clickInitReview', value)
}
export function recvMsg_2002805_WriteHanziReviewPage(data) {
  const value = data.data.value
  $bus.$emit('finishSingleHanziReview', value)
}
export function recvMsg_2002806_WriteHanziReviewPage(data) {
  const value = data.data.value
  $bus.$emit('writeHanziReviewClickMenu', value)
}

// AppleTreeGame组件事件
export function recvMsg_20029_AppleTreeGame(data) {
  const value = data.data.value
  $bus.$emit('clickBasketArea', value)
}

// ListenThenChooseWordsGame组件事件
export function recvMsg_2003001_ListenThenChooseWordsGame(data) {
  const value = data.data.value
  $bus.$emit('clickChoosedImg', value)
}
export function recvMsg_2003002_ListenThenChooseWordsGame(data) {
  const value = data.data.value
  $bus.$emit('clickChoosedHanzi', value)
}
export function recvMsg_2003003_ListenThenChooseWordsGame(data) {
  const value = data.data.value
  $bus.$emit('chooseImgGameClickAudio', value)
}
export function recvMsg_2003004_ListenThenChooseWordsGame(data) {
  const value = data.data.value
  $bus.$emit('chooseImgGameClickMenu', value)
}

// FrogJumpGame组件事件
export function recvMsg_20031_FrogJumpGame(data) {
  const value = data.data.value
  $bus.$emit('clickPinyinLeaf', value)
}

// RaceGame组件事件
export function recvMsg_20032_RaceGame(data) {
  const value = data.data.value
  $bus.$emit('clickRaceGame', value)
}

// speakingPage组件事件
export function recvMsg_2003301_speakingPage(data) {
  const value = data.data
  $bus.$emit('playVideoButton', value)
}
export function recvMsg_2003302_speakingPage(data) {
  const value = data.data.value
  $bus.$emit('clickVoiceButton', value)
}
export function recvMsg_2003303_speakingPage(data) {
  const value = data.data.value
  $bus.$emit('recordButton', value)
}
export function recvMsg_2003304_speakingPage(data) {
  const value = data.data.value
  $bus.$emit('nextRecordButton', value)
}
export function recvMsg_2003305_speakingPage(data) {
  const value = data.data.value
  $bus.$emit('palyRecorder', value)
}
export function recvMsg_2003306_speakingPage(data) {
  const value = data.data.value
  $bus.$emit('closeRecorder', value)
}

export function recvMsg_11000_speakingPage(data) {
  const value = data.data.value
  $bus.$emit('showFinishRecord', value)
}

// FindStrokeReviewGame组件事件
export function recvMsg_20034_FindStrokeReviewGame(data) {
  const value = data.data.value
  $bus.$emit('clickStrokeReview', value)
}

// BasketballPinyinGame组件事件
export function recvMsg_20035_BasketballPinyinGame(data) {
  const value = data.data.value
  $bus.$emit('clickBasketballGame', value)
}
export function recvMsg_2003501_BasketballPinyinGame(data) {
  const value = data.data.value
  $bus.$emit('clickBasketballGameAudio', value)
}

// puzzlePinyinGame组件事件
export function recvMsg_2003601_puzzlePinyinGame(data) {
  const value = data.data.value
  $bus.$emit('puzzleGameDrag', value)
}
export function recvMsg_2003602_puzzlePinyinGame(data) {
  const value = data.data.value
  $bus.$emit('puzzleGameDrop', value)
}
export function recvMsg_2003603_puzzlePinyinGame(data) {
  const value = data.data.value
  $bus.$emit('puzzleGameAudio', value)
}
export function recvMsg_2003604_puzzlePinyinGame(data) {
  const value = data.data.value
  $bus.$emit('puzzleGameClickMenu', value)
}

// DragCorrectPicGame组件事件
export function recvMsg_2003701_DragCorrectPicGame(data) {
  const value = data.data.value
  $bus.$emit('dragCorrectPicGame', value)
}
export function recvMsg_2003702_DragCorrectPicGame(data) {
  const value = data.data.value
  $bus.$emit('dropCorrectPicGame', value)
}

// RainDropGame组件事件
export function recvMsg_20038_RainDropGame(data) {
  const value = data.data.value
  $bus.$emit('clickRainDrop', value)
}

// PinyinMoveGame组件事件
export function recvMsg_20039_PinyinMoveGame(data) {
  const value = data.data.value
  $bus.$emit('clicPinyinkMoveGame', value)
}

// BingoGame组件事件
export function recvMsg_20040_BingoGame(data) {
  const value = data.data.value
  $bus.$emit('clicBingoGame', value)
}

// MatchTheCardsGame组件事件
export function recvMsg_20041_MatchTheCardsGame(data) {
  const value = data.data.value
  $bus.$emit('ClickTheCards', value)
}

// MatchTheGroupCardsGame组件事件
export function recvMsg_2004101_MatchTheGroupCardsGame(data) {
  const value = data.data.value
  $bus.$emit('ClickTheCardsGroup', value)
}
export function recvMsg_2004102_MatchTheGroupCardsGame(data) {
  const value = data.data.value
  $bus.$emit('ClickTheCardsGroupMenu', value)
}

// SpeakingByTurntableGame组件事件
export function recvMsg_20042_SpeakingByTurntableGame(data) {
  const value = data.data.value
  $bus.$emit('ClickTheNeedle', value)
}

// ChooseWordsByPicGame组件事件
export function recvMsg_20043_ChooseWordsByPicGame(data) {
  const value = data.data.value
  $bus.$emit('clickToChooseWords', value)
}
export function recvMsg_2004301_ChooseWordsByPicGame(data) {
  const value = data.data.value
  $bus.$emit('chooseWordsByPicGameClickMenu', value)
}

// ClickRevealGame组件事件
export function recvMsg_20044_ClickRevealGame(data) {
  const value = data.data.value
  $bus.$emit('ClickThePeople', value)
}

// DragStrokeToImgGame组件事件
export function recvMsg_2004501_DragStrokeToImgGame(data) {
  const value = data.data.value
  $bus.$emit('dropStrokeEvent', value)
}
export function recvMsg_2004502_DragStrokeToImgGame(data) {
  const value = data.data.value
  $bus.$emit('dragToImgEvent', value)
}
export function recvMsg_2004503_DragoverStrokeToImgGame(data) {
  const value = data.data.value
  $bus.$emit('dragoverImgEvent', value)
}

// SpeakingSentence组件事件
export function recvMsg_2004601_SpeakingSentence(data) {
  const value = data.data.value
  $bus.$emit('speakingSentenceMenu', value)
}
export function recvMsg_2004602_SpeakingSentence(data) {
  const value = data.data.value
  $bus.$emit('clickSpeakingSentenceImg', value)
}

// OverturnPuzzleGame组件事件
export function recvMsg_20047_OverturnPuzzleGame(data) {
  const value = data.data.value
  $bus.$emit('ClickOverturnImg', value)
}

// FamilyTreeGame组件事件
export function recvMsg_2004801_FamilyTreeGame(data) {
  const value = data.data.value
  $bus.$emit('dropAvatarEvent', value)
}
export function recvMsg_2004802_FamilyTreeGame(data) {
  const value = data.data.value
  $bus.$emit('dragAvatarEvent', value)
}

// ChoosePicBySentenceGame组件事件
export function recvMsg_20049_ChoosePicBySentenceGame(data) {
  const value = data.data.value
  $bus.$emit('ClickSentenceMenu', value)
}

// SeesawGame组件事件
export function recvMsg_20050_SeesawGame(data) {
  const value = data.data.value
  $bus.$emit('clickToChooseWordsSeesaw', value)
}
export function recvMsg_2005001_SeesawGame(data) {
  const value = data.data.value
  $bus.$emit('seesawGameClickMenu', value)
}

// TwitterGame组件事件
export function recvMsg_2005101_TwitterGame(data) {
  const value = data.data.value
  $bus.$emit('ClickTheScreen', value)
}
// TwitterGame组件事件
export function recvMsg_2005102_TwitterGame(data) {
  const value = data.data.value
  $bus.$emit('ClickTheHeart', value)
}

// ScratchPicGame组件事件
export function recvMsg_2005201_ScratchPicGame(data) {
  const value = data.data.value
  $bus.$emit('ClickEraseMenu', value)
}
export function recvMsg_2005202_ScratchPicGame(data) {
  const value = data.data.value
  $bus.$emit('ClickEraseImg', value)
}

// SpeakingBubbleGame组件事件
export function recvMsg_2005301_SpeakingBubbleGame(data) {
  const value = data.data.value
  $bus.$emit('speakingBubbleClickMenu', value)
}
export function recvMsg_2005302_SpeakingBubbleGame(data) {
  const value = data.data.value
  $bus.$emit('clickSpeakingBubble', value)
}

// MagnifierGame组件事件
export function recvMsg_20054_MagnifierGame(data) {
  const value = data.data.value
  $bus.$emit('clickMagnifierArea', value)
}

// TigerMachineGame组件事件
export function recvMsg_20055_TigerMachineGame(data) {
  const value = data.data.value
  $bus.$emit('ClickTigerMachinde', value)
}

// GuideGame组件事件
export function recvMsg_20056_GuideGame(data) {
  const value = data.data.value
  $bus.$emit('clickGuide', value)
}

// DragToGressGame组件事件
export function recvMsg_2005701_DragToGressGame(data) {
  const value = data.data.value
  $bus.$emit('dropImgEvent', value)
}
export function recvMsg_2005702_DragToGressGame(data) {
  const value = data.data.value
  $bus.$emit('dragImgEvent', value)
}
export function recvMsg_2005704_DragToGressGame(data) {
  const value = data.data.value
  $bus.$emit('dressDargGroupGameClickMenu', value)
}
export function recvMsg_2005703_DragToGroupCakeGame(data) {
  const value = data.data.value
  $bus.$emit('dragImgEventMenu', value)
}

// MirrorGame组件事件
export function recvMsg_20058_MirrorGame(data) {
  const value = data.data.value
  $bus.$emit('clickToCheckAnswer', value)
}

// WordImgCardsPage组件事件
export function recvMsg_20059_WordImgCardsPage(data) {
  const value = data.data.value
  $bus.$emit('clickSingleCardImg', value)
}

// ClickImgToShowSentenceGame组件事件
export function recvMsg_2006001_ClickImgToShowSentenceGame(data) {
  const value = data.data.value
  $bus.$emit('getIdSocket', value)
}
export function recvMsg_2006002_ClickImgToShowSentenceGame(data) {
  const value = data.data.value
  $bus.$emit('showSentenceSocket', value)
}

// OpenBoxToFindGame组件事件
export function recvMsg_20061_OpenBoxToFindGame(data) {
  const value = data.data.value
  $bus.$emit('clickImgToGetId', value)
}

// DragFruitsGanme组件事件
export function recvMsg_2006201_DragFruitsGanme(data) {
  const value = data.data.value
  $bus.$emit('dropFruitsStrokeEvent', value)
}
export function recvMsg_2006202_DragFruitsGanme(data) {
  const value = data.data.value
  $bus.$emit('dragToFruitsImgEvent', value)
}
export function recvMsg_2006203_DragFruitsGanme(data) {
  const value = data.data.value
  $bus.$emit('dragFruitsAudio', value)
}

// DrawingPage组件事件
export function recvMsg_20063_DrawingPage(data) {
  const value = data.data.value
  $bus.$emit('clickDrawPage', value)
}

export function recvMsg_2006301_DrawingGroupPage(data) {
  const value = data.data.value
  $bus.$emit('clickDrawPageMenu', value)
}

// FindElementGame组件事件
export function recvMsg_20064_FindElementGame(data) {
  const value = data.data.value
  $bus.$emit('clickToFindElement', value)
}

export function recvMsg_2006401_FindElementGroupGame(data) {
  const value = data.data.value
  $bus.$emit('FindElementGroupMenu', value)
}

// EatingFoodGame组件事件
export function recvMsg_20065_EatingFoodGame(data) {
  const value = data.data.value
  $bus.$emit('eatingFoodClick', value)
}

// lesson 1 Hover Point
// export function recvMsg_3100001(data) {
//   const value = data.data.value
//   $bus.$emit('hoverButton', value);
// }
// export function recvMsg_3100002(data) {
//   const value = data.data.value
//   $bus.$emit('hoverWord', value);
// }

// lesson 1 introductoion
export function recvMsg_30001(data) {
  const value = data.data.value
  $bus.$emit('clickIntroSteps', value)
}
export function recvMsg_3000101(data) {
  const value = data.data.value
  $bus.$emit('clickIntroAside', value)
}

// lesson 1 prcticePageOne
export function recvMsg_30002(data) {
  const value = data.data.value
  $bus.$emit('clickPractOneSteps', value)
}

// lesson 1 prcticePageFour
export function recvMsg_30005(data) {
  const value = data.data.value
  $bus.$emit('clickPractFourPage', value)
}
export function recvMsg_3000501(data) {
  const value = data.data.value
  $bus.$emit('clickPractFourPageMenu', value)
}

export function recvMsg_30006(data) {
  const value = data.data.value
  $bus.$emit('clickSpeakingPageOne', value)
}
export function recvMsg_3000601(data) {
  const value = data.data.value
  $bus.$emit('clickSpeakingPageOneAside', value)
}

// lesson 1 singPage
export function recvMsg_3000701(data) {
  const value = data.data.value
  $bus.$emit('singSlowAndQuick', value)
}
export function recvMsg_3000702(data) {
  const value = data.data.value
  $bus.$emit('playlyrics', value)
}
export function recvMsg_3000703(data) {
  const value = data.data.value
  $bus.$emit('showLyricsList', value)
}
export function recvMsg_3000704(data) {
  const value = data.data.value
  $bus.$emit('singPlay', value)
}

// lesson 2 reviewGame
export function recvMsg_30013(data) {
  const value = data.data.value
  $bus.$emit('clickReviewGamePage', value)
}
export function recvMsg_3001301(data) {
  const value = data.data.value
  $bus.$emit('reviewGamePageAside', value)
}

//擦玻璃
export function recvMsg_30016(data) {
  const value = data.data.value
  $bus.$emit('clickPracticePageTwo', value)
}

//镜子
export function recvMsg_3000401(data) {
  const value = data.data.value
  $bus.$emit('changeMirrorImgSocket', value)
}

// lesson 1 prcticePageTwo
export function recvMsg_3000301(data) {
  const value = data.data.value
  $bus.$emit('clickPractTwoAside', value)
}
export function recvMsg_3000302(data) {
  const value = data.data
  $bus.$emit('clickPractTwoChoose', value)
}

// // lesson 1 speakingPageOne
// export function recvMsg_3000601(data) {
//   const value = data.data.value
//   $bus.$emit('clickSpeakingPageOne', value);
// }

// lesson 1 WordsCard
export function recvMsg_30008(data) {
  const value = data.data.value
  $bus.$emit('wordsCardPage', value)
}
// lesson 1 WordsCard hover
export function recvMsg_31008(data) {
  const value = data.data.value
  $bus.$emit('wordsCardPageHover', value)
}
// lesson 1 summary hover
export function recvMsg_3100801(data) {
  const value = data.data.value
  $bus.$emit('summaryPageHover', value)
}

// lesson 2 RocketGame
export function recvMsg_20009(data) {
  const value = data.data.value
  $bus.$emit('wordsCardPage', value)
}

// lesson 2 DragOne
export function recvMsg_30010(data) {
  const value = data.data.value
  $bus.$emit('wordsCardPage', value)
}

// lesson 2 DragTwo
export function recvMsg_30011(data) {
  const value = data.data.value
  $bus.$emit('wordsCardPage', value)
}

// lesson 2 MoveGame
export function recvMsg_30012(data) {
  const value = data.data.value
  $bus.$emit('moveGamePage', value)
}

// lesson 2 speakingGame
export function recvMsg_3001401(data) {
  const value = data.data.value
  $bus.$emit('speakingGameButton', value)
}
export function recvMsg_3001402(data) {
  const value = data.data.value
  $bus.$emit('speakingGamePlay', value)
}
export function recvMsg_3001403(data) {
  const value = data.data.value
  $bus.$emit('speakingGameRead', value)
}

// lesson 2 PracticePageOne
export function recvMsg_30015(data) {
  const value = data.data.value
  $bus.$emit('clickPracticePageOne', value)
}

// lesson 2 PracticePageTwo
export function recvMsg_3001601(data) {
  const value = data.data.value
  $bus.$emit('practicePageTwoThumb', value)
}

// lesson 2 PracticeGame
export function recvMsg_3001701(data) {
  const value = data.data.value
  $bus.$emit('switchAnimal', value)
}
export function recvMsg_3001702(data) {
  const value = data.data.value
  $bus.$emit('goUp', value)
}
export function recvMsg_3001703(data) {
  const value = data.data.value
  $bus.$emit('goDown', value)
}
export function recvMsg_3001704(data) {
  const value = data.data.value
  $bus.$emit('goLeft', value)
}
export function recvMsg_3001705(data) {
  const value = data.data.value
  $bus.$emit('goRight', value)
}

// lesson 2 WriteGame
export function recvMsg_3001801(data) {
  const value = data.data.value
  $bus.$emit('startWrting', value)
}
export function recvMsg_3001802(data) {
  const value = data.data.value
  $bus.$emit('StrokeImgNum', value)
}

// lesson 2 learnReview
export function recvMsg_30019(data) {
  const value = data.data
  $bus.$emit('deleteWords', value)
}

// 视频状态
export function recvMsg_1000418(data) {
  // console.log(data,'data');

  $bus.$emit('changeVideoStatus', data.data)
}

// 音频状态
export function recvMsg_1000419(data) {
  $bus.$emit('changeAudioStatus', data.data)
}

// 新的画板交互
export function recvMsg_6666002(data) {
  $bus.$emit('socket_6666002', data.data)
}

// mounted() {
//   this.$bus.$on("clickIntroSteps", (val) => {
//     this.numClick(val - 1);
//   });
// },
// beforeDestroy() {
//   this.$bus.$off("clickIntroSteps");
// },

// 课件内容刷新
export function getMessage_10001(data) {
  $bus.$emit('socket_10001', data.data)
}
// 课件列表刷新
export function getMessage_10002(data) {
  $bus.$emit('socket_10002', data.data)
}
// 课件详情开关改变
export function getMessage_10003(data) {
  $bus.$emit('socket_10003', data.data)
}
// 音视频操作
export function getMessage_10004(data) {
  $bus.$emit('socket_10004', data.data)
}
// 获取视频状态
export function getMessage_10004002(data) {
  $bus.$emit('socket_10004002', data.data)
}
// 同步视频状态
export function getMessage_10004003(data) {
  console.log(data, 'data')

  $bus.$emit('socket_10004003', data.data)
}
// 聊天消息发送
export function getMessage_10005(data) {
  $bus.$emit('socket_10005', data.data)
}
// 聊天系统消息发送
export function getMessage_10005001(data) {
  $bus.$emit('socket_10005001', data.data)
}
