<template>
  <div class="PARTICOPANTS">
    <!-- head -->
    <div class="head">
      <div class="left font_two fontWeight_regular">
        <p>{{ head_left_text }}</p>
      </div>
      <div class="right"></div>
    </div>

    <!-- main content -->
    <div class="mainContent customScrollbar">
      <!-- list -->
      <div
        class="peopleCont"
        v-for="(people, index) in peopleList"
        :key="index"
      >
        <!-- some info -->
        <div class="someInfo">
          <NewImg class="people_head" :src="people.photo"></NewImg>
          <p class="font_zero fontWeight_regular">{{ people.nickname }}</p>
        </div>
        <!-- option group -->
        <div class="optionGroup">
          <!-- video -->
          <NewImg
            class="icon_video"
            :src="
              people.type_video ? people.icon_video : people.icon_video_close
            "
            @click.native="option_video(people, index)"
          ></NewImg>
          <!-- phone -->
          <NewImg
            class="icon_video"
            :src="
              people.type_phone ? people.icon_phone : people.icon_phone_close
            "
            @click.native="option_phone(people, index)"
          ></NewImg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 事件中心
// import this.$bus from "@/common/js/eventCenter";
// api
// import { getCalendarEventUser } from "@/common/axios/api";
import { getUrlParam, deepCopy } from "@/utils/rejular";

let icon_video = require("@/assets/img/19-Dashboard/icon-camera-blue.svg");
let icon_video_close = require("@/assets/img/19-Dashboard/icon-camera-blue-off.svg");
let icon_video_hover = require("@/assets/img/19-Dashboard/icon-camera-yellow.svg");

let icon_phone = require("@/assets/img/19-Dashboard/icon-micro-on.svg");
let icon_phone_close = require("@/assets/img/19-Dashboard/icon-micro-off.svg");
let icon_phone_hover = require("@/assets/img/19-Dashboard/icon-phone-blue-hover.png");

export default {
  components: {
    NewImg: () => import("../NewImg"),
  },
  computed: {
    head_left_text() {
      let str = "Students";
      let num = this.courseData.studenVOList
        ? this.courseData.studenVOList.length
        : 0;
      if (num) {
        str = `${str} (${num})`;
      }

      return str;
    },
  },
  data() {
    return {
      courseData: {},
      peopleList: [],
    };
  },
  methods: {
    // 数据初始化
    initData() {
      // let calendarEventId = getUrlParam("calendarEventId");
      // this.courseData = this.$store.getters.getCourseData[calendarEventId];
      // console.log(this.courseData);

      let list = [];
      this.courseData.studenVOList = [
        {
          id: 27577,
          // nickname: "chad_student  chad_student",
          nickname: "test_student",
        },
      ]; // 假数据
      if (this.courseData.studenVOList) {
        list = deepCopy(this.courseData.studenVOList);
      }

      list.forEach((val) => {
        this.$set(val, "type_video", false);
        this.$set(val, "icon_video_close", icon_video_close);
        this.$set(val, "icon_video", icon_video);
        this.$set(val, "type_phone", false);
        this.$set(val, "icon_phone", icon_phone);
        this.$set(val, "icon_phone_close", icon_phone_close);
      });
      this.peopleList = list;
    },
    // 获取课堂学生列表
    // option video
    option_video(people) {
      this.$bus.$emit("realTimeVideo_group_optionVideo", people);
    },
    // option phone
    option_phone(people) {
      this.$bus.$emit("realTimeVideo_group_optionPhone", people);
    },
    /**
     * 事件中心事件
     * **/
    interact_particopants_equal(people) {
      console.log(people);
      for (let [index, data] of this.peopleList.entries()) {
        if (data.id == people.id) {
          data.type_video = people.type_video;
          data.type_phone = people.type_phone;
          break;
        }
      }
    },
  },
  mounted() {
    this.initData();
    // 注册房间人数列表状态同步事件
    this.$bus.$on(
      "interact_particopants_equal",
      this.interact_particopants_equal
    );
  },
  beforeDestroy() {
    this.$bus.$off("interact_particopants_equal");
  },
};
</script>

<style scoped lang="scss">
.PARTICOPANTS {
  border: 1px solid #f1f1f0;
  border-radius: 30px;
  overflow: hidden;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 10px;
  overflow: hidden;
  .head {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
  }
  .mainContent {
    flex: 1;
    width: 100%;
    height: 10px;
    background-color: #f1f1f0;
    padding: 20px;
    box-sizing: border-box;
    .peopleCont {
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .someInfo {
        display: flex;
        align-items: center;
        .people_head {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 15px;
        }
      }
      .optionGroup {
        display: flex;
        align-items: center;
        .new_img {
          width: 20px;
          height: 20px;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
