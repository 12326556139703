<template>
  <div class="header-container">
    <div class="layout-lesson">
      <div class="student-left-back" @click="backToHome"></div>
      <div class="handle-word-wrap font-lesson-details">
        LESSON {{ lessonNum }}
      </div>
      <!-- progressDotCurrent: item.current, -->
      <ul class="progressBar">
        <li v-for="(item, index) in pageNumTotal" :key="index">
          <el-tooltip
            class="item"
            effect="dark"
            :content="item + ''"
            placement="bottom"
          >
            <span
              :class="{
                progressDotFinish: item <= pageNum,
                progressDotCurrent: item === pageNum
              }"
              class="progressDot"
              @click="jumpPage(item)"
            ></span>
          </el-tooltip>
        </li>
      </ul>
    </div>
    <div class="student-right" v-if="!preView">
      <!-- <div class="chat-area">
        <div
          class="student-chat hover-opacity"
          @click="navShowClick()"
          @mouseenter="isNavHover = true"
          @mouseleave="isNavHover = false"
          v-show="false"
        >
          <img
            v-if="!isNavHover && !isShowNav"
            src="@/assets/img/01-Menu/menu-materials.svg"
            alt=""
          />
          <img
            v-if="isNavHover && !isShowNav"
            src="@/assets/img/01-Menu/menu-materials-active.svg"
            alt=""
          />
          <img
            v-if="isShowNav"
            src="@/assets/img/01-Menu/menu-materials-active.svg"
            alt=""
          />
        </div>
        <div
          class="student-chat hover-opacity"
          @click="chatShowClick()"
          @mouseenter="isChatHover = true"
          @mouseleave="isChatHover = false"
        >
          <img
            v-if="!isChatHover && !isShowChat"
            src="@/assets/img/01-Menu/menu-chat.svg"
            alt=""
          />
          <img
            v-if="isChatHover && !isShowChat"
            src="@/assets/img/01-Menu/menu-chat-active.svg"
            alt=""
          />
          <img
            v-if="isShowChat"
            src="@/assets/img/01-Menu/menu-chat-active.svg"
            alt=""
          />
        </div>
      </div> -->
 
      <div class="student-shou">
        <div class="student-yuan">
          <span></span>
          <span class="font-lesson-details">In class &nbsp;| &nbsp;</span>
          <span class="font-lesson-details">{{ countDown }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassInfoById } from '@/assets/js/axios/kidsApi'
export default {
  name: 'CourseHeader',
  props: {
    lessonNum: {
      type: Number,
      require: true
    },
    pageNum: {
      type: Number,
      require: true
    },
    pageNumTotal: {
      type: Number,
      require: true
    },
    authority: {
      //权限开关
      type: Boolean,
      require: true
    },
    preView: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  filters: {
    // 视频时间进度
    secTOmin(val) {
      if (typeof val === 'number') {
        val = val.toFixed(0)
        let min = Math.floor(val / 60)
        let sec = val % 60
        return `${min}：${sec < 10 ? `0${sec}` : sec}`
      }
    }
  },
  data() {
    return {
      isNavHover: false,
      isShowNav: false,
      isShowChat: false,
      isChatHover: false,
      // 上课时间倒计时
      countDown: 0,
      courseData: {
        startTime: '',
        endTime: ''
      }
    }
  },
  mounted() {
    this.$bus.$on('closeNav', () => {
      this.isShowNav = false
    })
    this.$bus.$on('closeChat', () => {
      this.isShowChat = false
    })
  },
  beforeDestroy() {
    this.$bus.$off('closeNav')
    this.$bus.$off('closeChat')
  },
  created() {
    this.getClassInfo()
  },
  methods: {
     async getClassInfo() {
      if(this.preView) return
      const { code, data } = await getClassInfoById({
        calendarEventId: this.$route.params.calendarEventId
      })
      if (code === 200) {
        console.log(data)
        this.courseData = data
        this.courseCountDown()
      }
    },
    // 课程倒计时
    courseCountDown() {
      const nowTime = new Date().getTime()
      const startTime = this.courseData.startTime
      const endTime = this.courseData.endTime

      // 计算剩余时间
      let remainingTime = 0

      if (nowTime < startTime) {
        // 当前时间小于开始时间
        remainingTime = Math.floor((startTime - nowTime) / 1000)
      } else if (nowTime >= startTime && nowTime < endTime) {
        // 当前时间在开始时间和结束时间之间
        remainingTime = Math.floor((endTime - nowTime) / 1000)
      } else {
        // 当前时间大于结束时间
        this.countDown = '00:00'
        clearInterval(this.timer)
        return
      }

      // 格式化剩余时间
      const minutes = Math.floor(remainingTime / 60)
        .toString()
        .padStart(2, '0')
      const seconds = (remainingTime % 60).toString().padStart(2, '0')
      this.countDown = `${minutes}:${seconds}`

      // 设置定时器
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.courseCountDown()
        }, 1000)
      }
    },
    jumpPage(index) {
      if (!this.authority) {
        return
      }
      console.log(index)
      this.$emit('jumpTo', index)
    },
    navShowClick() {
      this.isShowNav = true
      this.isShowChat = false
      // 注册点击展示课程nav窗口事件
      this.$bus.$emit('showCourseNav', true)
    },
    chatShowClick() {
      this.isChatHover = true
      this.isShowNav = false
      this.isShowChat = true
      //   this.getUserCourseSetting();
      // 点击展示chatting窗口事件
      this.$bus.$emit('showChatting', true)
    },

    backToHome() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  padding: 3px 20px;
  height: 54px;
  background-color: #cd4c3f;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  font-weight: 600;

  .layout-lesson {
    flex: 2;
    display: flex;
    cursor: default;
    .student-left-back {
      flex-shrink: 0;
      width: 28px;
      height: 20px;
      border-radius: 48%;
      margin-top: 9px;
      padding: 5px 3px;
      margin-right: 18px;
      &:hover {
        cursor: pointer;
      }
      background-image: url('../../../assets/img/01-Menu/menu-back.svg');
      background-repeat: no-repeat;
      &:hover {
        background-image: url('../../../assets/img/01-Menu/menu-back-hover.svg');
      }
    }
    .font-lesson-details {
      flex: 70px 0 0;
    }
    .progressBar {
      margin-left: 40px;
      display: flex;
      li {
        display: flex;
        align-items: center;
        margin-right: 7px;
        cursor: pointer;
        .progressDot {
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #dc8178;
          border-radius: 50%;

          &:hover {
            background: #f2d2cf;
          }
        }
        .progressDotFinish {
          background: #fff !important;
        }
        .progressDotCurrent {
          background: #cd4c3f !important;
          border: 1.5px solid #fff;
          box-sizing: border-box;
        }
      }
    }
    div:nth-child(2) {
      color: #ffffff;
      font-size: 13px;
      font-family: 'GenSenRoundedTW';
      line-height: 51px;
      font-weight: 600;
    }
  }
  .student-right {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    .chat-area {
      width: 40%;
      display: flex;
      border-right: 1px solid #fff;
      justify-content: flex-end;
      .student-chat {
        width: 30%;
        height: 32px;
        padding-top: 3px;
        text-align: center;
        padding-right: 3%;
        &:hover {
          cursor: pointer;
        }
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .student-shou {
      width: 35%;
      height: 32px;
      // border-right: 1px solid #fff;
      margin-left: 10px;
      .student-yuan {
        margin: auto;
        width: 85%;
        background-color: #f48d83;
        border-radius: 20px;
        text-align: center;
        padding: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
        span:nth-child(1) {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #fff;
          border-radius: 50%;
          margin-right: 5px;
        }
        span:nth-child(2) {
          line-height: 32px;
          display: inline-block;
          color: #ffffff;
          font-size: 13px;
          font-family: 'GenSenRoundedTW';
          font-weight: 600;
        }
        span:nth-child(3) {
          line-height: 32px;
          display: inline-block;
          color: #ffffff;
          font-size: 13px;
          font-family: 'GenSenRoundedTW';
          font-weight: 600;
        }
      }
    }
    // .class-time-area {
    //   width: 35%;
    //   height: 32px;
    //   margin-left: 3%;
    //   .class-time-content {
    //     margin: auto;
    //     width: 85%;
    //     background-color: #f48d83;
    //     border-radius: 20px;
    //     text-align: center;
    //     padding: 0 5px;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    //     cursor: default;
    //     span:nth-child(1) {
    //       display: inline-block;
    //       width: 10px;
    //       height: 10px;
    //       background-color: #fff;
    //       border-radius: 50%;
    //       margin-right: 5px;
    //     }
    //     span:nth-child(2) {
    //       line-height: 32px;
    //       display: inline-block;
    //       color: #ffffff;
    //       font-size: 13px;
    //       font-family: "GenSenRoundedTW";
    //       font-weight: 600;
    //     }
    //     span:nth-child(3) {
    //       line-height: 32px;
    //       display: inline-block;
    //       color: #ffffff;
    //       font-size: 13px;
    //       font-family: "GenSenRoundedTW";
    //       font-weight: 600;
    //     }
    //   }
    // }
  }
}
</style>
