<template>
  <!-- layout-container -->
  <div class="layout-container" v-if="lessonInfo">
    <div class="layout-header">
      <CourseHeader
        :lessonNum="lessonInfo.lesson"
        :pageNum="pageNum"
        :pageNumTotal="lessonInfo.totalPage"
        @jumpTo="changePage"
        :authority="AUTH"
      />
    </div>
    <div class="layout-content">
      <div class="layout-content-left">
        <!-- page 标题 -->
        <div class="layout-content-left-header">
          <!-- 课程标题 -->
          <div class="page-title-box">
            <PageTitle
              :pageIndex="pageNum"
              @displayWhichPage="showPage"
              :pageTitleList="lessonInfo.pageTitleArray"
              :authority="AUTH"
            />
          </div>

          <!-- 白板控制器  翻页 -->
          <div class="control-box">
            <div class="white-board-control" v-if="AUTH && !isShowTeacherMouse">
              <WhiteBoardControl
                :whiteBoradPage="controlWriteboard"
                @globalAuthority="handleAuthority"
              />
            </div>
            <div class="page-turning">
              <div
                class="previous"
                @click="prevClick"
                :style="{ visibility: pageNum > 1 ? 'visible' : 'hidden' }"
              ></div>
              <div
                class="nextPrevious"
                @click="nextClick"
                :style="{
                  visibility:
                    pageNum < lessonInfo.totalPage ? 'visible' : 'hidden'
                }"
              ></div>
            </div>
          </div>
        </div>
        <!-- 课程主体部分 -->
        <div class="layout-content-left-display">
          <div class="shade-box" v-if="!AUTH"></div>
          <div
            class="teacher-mouse-move-area"
            v-if="isShowTeacherMouse"
            @mousemove="handleMousemove"
          ></div>
          <CourseContent
            :componentsList="courseComponents"
            v-if="courseComponents.length"
          />
        </div>
      </div>

      <!-- 交互区 -->
      <div class="layout-content-right">
        <Interaction :authority="AUTH" />
      </div>
    </div>
  </div>
</template>
<script>
import CourseHeader from '@/components/Course/Header'
import PageTitle from '@/components/Course/PageTitle'
import Interaction from '@/components/Course/Interaction'
import WhiteBoardControl from '@/components/Course/WhiteBoard/WhiteBoardControl.vue'
import CourseContent from './CourseContent.vue'

import { webSocketDefault } from '@/mixins/kidsSocketDefault.js'
// webcsocket 事件
import { socketSwitch } from '@/utils/socketSwitch.js'

import {
  getKidsCoursewareDetail,
  getKidsCourseDataByEnumId
} from '@/assets/js/axios/coursewareEditor.js'

export default {
  name: 'Course',
  mixins: [webSocketDefault],
  components: {
    CourseHeader,
    PageTitle,
    Interaction,
    WhiteBoardControl,
    CourseContent
  },
  data() {
    return {
      changePageBtn: 0,
      changePageNum: 0,
      pageNum: 1, // 默认第一页
      controlWriteboard: false,
      showWhiteBoard: false,
      lessonInfo: null,
      sendSocketInfo: {}, // socket发送信息对象格式
      isTipsHover: false,
      isShowTips: false,
      showNotify: false,
      isShowTeacherMouse: false,

      courseComponents: [],
      AUTH: false
    }
  },
  computed: {},
  watch: {
    $route: {
      // 根据路由变化切换课件内容，
      // 学生端在收到该socket信息后同步更改路由信息切换课件
      async handler(val) {
        const { params } = val
        console.log('路由变化：', params)
        const { lessonNo, lessonId } = params
        await this.initCourseData()
        const data = {
          clickType: 10002,
          data: {
            value: {
              lessonId,
              lessonNo
            }
          },
          text: '测试切换课件按钮socket'
        }
        this.sendSocketInfo = { ...data }
        this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
      },
      deep: true
    },
    changePageBtn() {
      // 给页面发送点击  progress  或者  上下一页按钮
      // 测试socket
      let pageInfo = { val: this.pageNum, type: 1 }
      const data = {
        clickType: 10001,
        data: pageInfo,
        text: '测试所有翻页按钮socket'
      }
      this.sendSocketInfo = { ...data }
      this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    },
    changePageNum() {
      // 给页面发送点击  progress  或者  上下一页按钮
      // 测试socket
      let pageInfo = { val: this.pageNum, type: 1 }
      const data = {
        clickType: 10001,
        data: pageInfo,
        text: '测试所有翻页按钮socket'
      }
      this.sendSocketInfo = { ...data }
      this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    },
    pageNum(index) {
      this.$bus.$emit('changeCoursePage', index)
    }
  },
  mounted() {
    // 监听页面内button跳转下一页
    this.$bus.$on('changePageIndex', (index) => {
      this.changePage(index.val, index.type)
    })
    // soket触发切换课件
    this.$bus.$on('kidsAuthority', (value) => {
      const test = value ? '打开权限' : '关闭权限'
      console.log(test)

      this.AUTH = value
    })
    // 监听触发小手模式
    this.$bus.$on('teacherMouseMove', (value) => {
      this.isShowTeacherMouse = value
      // console.log("teacherMouseMove", value);
    })
  },

  beforeDestroy() {
    this.$bus.$off('changePageIndex')
    this.$bus.$off('kidsSocketSwitchLesson')
    this.$bus.$off('kidsAuthority')
    this.$bus.$off('teacherMouseMove')
  },
  created() {
    this.initCourseData()
    // this.$store.state.toolBar.isTeacherPoint = false;
  },

  methods: {
    /**
     * @description: 初始化课件数据
     * @return {*}
     */
    async initCourseData() {
      this.pageNum = 1
      const lessonId = Number(this.$route.params.lessonId)
      const { chapterId, levelId } = await this.checkLessonId(lessonId)
      await this.getCourseData(levelId, chapterId, lessonId)
    },

    /**
     * @description: 根据lessonId 获取chapterId 和 levelId
     * @param {*} lessonId
     * @return {*}
     */
    async checkLessonId(lessonId) {
      if (!lessonId) {
        return
      }
      try {
        // 第一次请求，获取 bassId
        const { code, data } = await getKidsCourseDataByEnumId({
          enumId: 292,
          id: lessonId
        })
        if (code === 200) {
          const { parentId: id } = data && data[0]
          // 第一次查找的 parentId 作为 chapterId
          // 使用 chapterId 再次查找 levelId
          const { code: levelCode, data: levelData } =
            await getKidsCourseDataByEnumId({
              enumId: 292,
              id
            })
          if (levelCode === 200) {
            const levelId = levelData && levelData[0].parentId
            // 返回 chapterId 和 levelId
            return { id, levelId }
          }
        }
      } catch (error) {
        console.error('获取课程数据失败:', error)
      }
    },

    /**
     * @description: 获取课程信息
     * @return {*}
     */
    async getCourseData(levelId, chapterId, lessonId) {
      const { data, code } =
        (await getKidsCoursewareDetail({
          chapterId,
          lessonId,
          levelId
        })) || {}
      if (code === 200) {
        // console.log(data)
        const { title: lesson, contentList } = data
        const allPages = contentList.filter((item) => item.content)
        // 初始化课程页面信息
        this.lessonInfo = {
          lesson: Number(lesson ? lesson.split('LESSON')[1] : 1),
          totalPage: allPages.length,
          pageTitleArray: allPages.map((item) => {
            return {
              title: item.templateTypeName,
              // isTheme: item.fieldName === '4_CourseSyllabus'
              isTheme: false
            }
          })
        }
        // 初始化组件信息
        const formatList = allPages.map((item) => {
          return {
            ...item,
            content: JSON.parse(item.content) || []
          }
        })
        this.initComponentsList(formatList)
      }
    },
    /**
     * @description: 初始化组件列表
     * @param {*} list
     * @return {*}
     */
    async initComponentsList(list) {
      const allComponents = await Promise.all(
        list.map((item) => this.loadComponent(item.fieldName))
      )

      this.courseComponents = allComponents.map((component, index) => {
        let componentProps = {}
        list[index].content.forEach((ele) => {
          componentProps[ele.fieldName] = ele.value
        })
        return {
          component,
          componentProps
        }
      })

      // console.log(this.courseComponents)
    },
    /**
     * @description: 获取组件列表和数据
     * @param {*} componentName
     * @return {*}
     */
    loadComponent(componentName) {
      return new Promise((resolve) => {
        require([
          '../CoursewareEditor/components/GameAndPage/' + componentName
        ], (component) => {
          resolve(component.default || component)
        })
      })
    },

    // ----------------------------------------------------------------------

    handleMousemove(e) {
      const data = {
        clickType: 1000514,
        data: {
          x: e.offsetX / e.target.clientWidth,
          y: e.offsetY / e.target.clientHeight
        },
        text: 'MagnifierGame点击socket'
      }
      this.sendSocketInfo = { ...data }
      this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    },

    // socket接收信息
    getMessage(msg) {
      let data = JSON.parse(msg.data)
      // console.log(data)
      // console.log('获取socket信息', data.clickType)
      socketSwitch(data)
    },
    // 跳页面
    changePage(value, type) {
      if (type != 1) {
        this.changePageNum++
      }
      this.pageNum = value
    },
    // 权限
    handleAuthority(value) {
      // console.log(value);
      // 测试socket
      const data = {
        clickType: 10000,
        data: { value },
        text: '测试全局权限按钮socket'
      }
      this.sendSocketInfo = { ...data }
      this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    },
    // 切换白板和课程页面
    showPage(value) {
      // 给页面发送切换课程目标页

      this.$bus.$emit('switchPageType', value)
      // 测试socket
      const data = {
        clickType: 10003,
        data: { value },
        text: '测试切换课程目标页面按钮socket'
      }
      this.sendSocketInfo = { ...data }
      this.$bus.$emit('kids_webSocket_sendInfo', this.sendSocketInfo)
    },
    nextClick() {
      if (!this.AUTH) {
        return
      }
      this.pageNum++
      this.changePageBtn++
    },
    prevClick() {
      if (!this.AUTH) {
        return
      }
      // console.log("上一页");
      this.pageNum--
      this.changePageBtn++
    }
  }
}
</script>
>

<style lang="scss" scoped>
.layout-container {
  width: 100%;
  // display: flex;
  height: 100%;
  flex-direction: row;
  position: relative;

  .shade-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    // cursor: not-allowed;
  }

  .layout-content {
    width: 100%;
    height: calc(100vh - 54px);
    background-color: #224e96;
    background-image: url('../../assets/img/03-Backgrounds/background.svg');
    display: flex;
    // padding: 0 4px;
    box-sizing: border-box;

    .layout-content-left {
      width: calc(65% - 4px);
      height: calc(100% - 4px);
      // overflow: hidden;
      padding-left: 4px;
      padding-bottom: 4px;

      .layout-content-left-header {
        width: 100%;
        height: 7%;
        min-height: 60px;
        display: flex;

        .page-title-box {
          // flex: 50% 1 1;
          flex: 2;
          // margin-left: 6%;
          margin-left: 50px;
          display: flex;
          position: relative;
        }

        .control-box {
          // flex: 50% 1 1;
          flex: 3;
          display: flex;
          position: relative;
          justify-content: flex-end;
          align-items: center;
          margin-top: 0px;
          margin-right: 25px;

          // 修改操作板占位样式
          .white-board-control {
            flex: 4;
            height: 35px;
            margin-right: 20px;
          }

          .page-turning {
            flex: 1;
            top: 22%;
            right: 0;
            display: flex;
            align-self: center;
            justify-content: flex-end;

            .previous:hover,
            .nextPrevious:hover {
              cursor: pointer;
            }

            .previous {
              width: 42px;
              height: 42px;
              background-image: url('../../assets/img/04-Buttons/button-previous-page.svg');
              margin-right: 10px;
            }

            .previous:hover {
              background-image: url('../../assets/img/04-Buttons/button-previous-page-hover.svg');
            }

            .nextPrevious {
              width: 42px;
              height: 42px;
              background-image: url('../../assets/img/04-Buttons/button-next-page.svg');
            }

            .nextPrevious:hover {
              background-image: url('../../assets/img/04-Buttons/button-next-page-hover.svg');
            }
          }
        }
      }

      .layout-content-left-display {
        width: 100%;
        height: calc(100% - 60px);
        border-radius: 56px;
        // background-color: #fff;
        position: relative;

        .teacher-mouse-move-area {
          border-radius: 58px;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          line-height: 1;
          // background: rgba(255, 255, 255, .3);
          z-index: 11;
        }
      }
    }

    .layout-content-right {
      width: calc(35% - 8px);
      height: calc(100% - 8px);
      padding: 4px;
    }
  }
}

//  fade in animation for transition
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadein {
  color: white;
  -webkit-animation: fadein 0.5s ease-in forwards;
  -moz-animation: fadein 0.5s ease-in forwards;
  animation: fadein 0.5s ease-in forwards;
}
</style>
