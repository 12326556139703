var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "whiteBoard-container" }, [
    _c("div", { staticClass: "control-box" }, [
      _c("div", { staticClass: "control-box-content" }, [
        _c("div", { staticClass: "control-box-area" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShapeUse,
                  expression: "isShapeUse",
                },
              ],
              staticClass: "select-area-item",
            },
            [
              _c(
                "div",
                { staticClass: "shape-content" },
                _vm._l(_vm.shapeTypeList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          return _vm.chooseShape(index)
                        },
                      },
                    },
                    [
                      _vm.shapeType === index
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/02-Toolbar/" +
                                item.name +
                                "-active.svg"),
                              title: item.chinese,
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/img/02-Toolbar/" +
                                item.name +
                                ".svg"),
                              title: item.chinese,
                            },
                          }),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isBrushOpen,
                  expression: "isBrushOpen",
                },
              ],
              staticClass: "select-area-item",
            },
            [
              _c(
                "div",
                { staticClass: "line-width-content" },
                _vm._l(_vm.lineWidthArr, function (item, index) {
                  return _c("span", {
                    key: index,
                    staticClass: "line-width-item",
                    class: {
                      "line-width-active": item.value === _vm.lineWidth,
                    },
                    style: { width: item.width, height: item.width },
                    on: {
                      click: function ($event) {
                        return _vm.chooseLineWidth(item.value)
                      },
                    },
                  })
                }),
                0
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isBrushOpen,
                  expression: "isBrushOpen",
                },
              ],
              staticClass: "select-area-item",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBrushOpen || _vm.isShapeUse,
                      expression: "isBrushOpen || isShapeUse",
                    },
                  ],
                  staticClass: "color",
                },
                [
                  _c("div", {
                    staticClass: "color-area",
                    style: { background: _vm.drawColor },
                    on: {
                      click: function ($event) {
                        _vm.isColorChooseUse = true
                      },
                    },
                  }),
                  _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
                    _vm.isColorChooseUse
                      ? _c(
                          "div",
                          { staticClass: "color-box" },
                          _vm._l(_vm.predefineColors, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "color-border-box",
                                class: {
                                  "active-color": _vm.currentColorNum === index,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseColor(item)
                                  },
                                  mouseenter: function ($event) {
                                    _vm.currentColorNum = index
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "color-item",
                                  style: {
                                    background: item,
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isBrushOpen || _vm.isShapeUse,
                  expression: "isBrushOpen || isShapeUse",
                },
              ],
              staticClass: "dis-line",
            },
            [_vm._v("|")]
          ),
          _c(
            "div",
            { staticClass: "brush item", on: { click: _vm.handleChangePen } },
            [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isBrushOpen,
                    expression: "!isBrushOpen",
                  },
                ],
                attrs: {
                  src: require("@/assets/img/02-Toolbar/tool-brush.svg"),
                  title: "笔刷",
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBrushOpen,
                    expression: "isBrushOpen",
                  },
                ],
                attrs: {
                  src: require("@/assets/img/02-Toolbar/tool-brush-active.svg"),
                  title: "笔刷",
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "shape item", on: { click: _vm.handleChooseShape } },
            [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShapeUse,
                    expression: "isShapeUse",
                  },
                ],
                attrs: {
                  src: require("@/assets/img/02-Toolbar/tool-shapes-active.svg"),
                  title: "形状",
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isShapeUse,
                    expression: "!isShapeUse",
                  },
                ],
                attrs: {
                  src: require("@/assets/img/02-Toolbar/tool-shapes.svg"),
                  title: "形状",
                },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "eraser item", on: { click: _vm.handleEraser } },
            [
              !_vm.isEraserUse
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/img/02-Toolbar/tool-eraser.svg"),
                      title: "橡皮擦",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/img/02-Toolbar/tool-eraser-active.svg"),
                      title: "橡皮擦",
                    },
                  }),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }