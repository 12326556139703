/*
 * @Author: shmily
 * @Date: 2021-10-22 13:03:13
 * @LastEditTime: 2021-11-08 10:07:44
 * @LastEditors: shmily
 * @Description: 
 * @FilePath: \newmc-Front-kids\src\eventsBus.js
 */
import Vue from "vue"

const app = new Vue({})
Vue.prototype.$bus = app
export default app
