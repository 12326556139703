var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lessonInfo
    ? _c("div", { staticClass: "layout-container" }, [
        _c(
          "div",
          { staticClass: "layout-header" },
          [
            _c("CourseHeader", {
              attrs: {
                lessonNum: _vm.lessonInfo.lesson,
                pageNum: _vm.pageNum,
                pageNumTotal: _vm.lessonInfo.totalPage,
                authority: _vm.AUTH,
              },
              on: { jumpTo: _vm.changePage },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "layout-content" }, [
          _c("div", { staticClass: "layout-content-left" }, [
            _c("div", { staticClass: "layout-content-left-header" }, [
              _c(
                "div",
                { staticClass: "page-title-box" },
                [
                  _c("PageTitle", {
                    attrs: {
                      pageIndex: _vm.pageNum,
                      pageTitleList: _vm.lessonInfo.pageTitleArray,
                      authority: _vm.AUTH,
                    },
                    on: { displayWhichPage: _vm.showPage },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "control-box" }, [
                _vm.AUTH && !_vm.isShowTeacherMouse
                  ? _c(
                      "div",
                      { staticClass: "white-board-control" },
                      [
                        _c("WhiteBoardControl", {
                          attrs: { whiteBoradPage: _vm.controlWriteboard },
                          on: { globalAuthority: _vm.handleAuthority },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "page-turning" }, [
                  _c("div", {
                    staticClass: "previous",
                    style: {
                      visibility: _vm.pageNum > 1 ? "visible" : "hidden",
                    },
                    on: { click: _vm.prevClick },
                  }),
                  _c("div", {
                    staticClass: "nextPrevious",
                    style: {
                      visibility:
                        _vm.pageNum < _vm.lessonInfo.totalPage
                          ? "visible"
                          : "hidden",
                    },
                    on: { click: _vm.nextClick },
                  }),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "layout-content-left-display" },
              [
                !_vm.AUTH ? _c("div", { staticClass: "shade-box" }) : _vm._e(),
                _vm.isShowTeacherMouse
                  ? _c("div", {
                      staticClass: "teacher-mouse-move-area",
                      on: { mousemove: _vm.handleMousemove },
                    })
                  : _vm._e(),
                _vm.courseComponents.length
                  ? _c("CourseContent", {
                      attrs: { componentsList: _vm.courseComponents },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "layout-content-right" },
            [_c("Interaction", { attrs: { authority: _vm.AUTH } })],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }