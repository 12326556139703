<template>
  <div class="class-page-container">
    <div class="display-page" v-if="pageType === 0 && componentsList.length">
      <template v-for="(item, index) in componentsList">
        <component
          class="display-page"
          :key="index"
          :is="item.component"
          v-if="index + 1 === pageNum"
          :componentProps="item.componentProps"
        >
        </component>
      </template>
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB && pageType !== 2">
      <!-- 白板部分 -->
      <WhiteBoard />
    </div>
  </div>
</template>
<script>
import WhiteBoard from '@/components/Course/WhiteBoard/WhiteBoard.vue'

export default {
  name: 'CoursePage',
  components: { WhiteBoard },
  props: {
    componentsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      pageNum: 1,
      globalWhiteB: false,
      pageType: 0
    }
  },
  async mounted() {
    // console.log(this.componentsList)
    // 接收主页面的canvas展示事件
    this.$bus.$on('openPageWhiteBoard', () => {
      console.log('打开页面白板')
      this.globalWhiteB = false
    })
    this.$bus.$on('OpenGlobalWhiteBoard', (val) => {
      console.log('打开全局白板')
      this.globalWhiteB = val
    })
    // 接收所有课程页面的next按钮事件
    this.$bus.$on('nextButton', () => {
      this.pageNum++
      if (this.pageType === 1) {
        this.pageType = 0
      }
      let changePage = {
        val: this.pageNum,
        type: 2
      }
      this.$bus.$emit('changePageIndex', changePage)
    })
    // 接收header部分的页面跳转事件
    this.$bus.$on('changeCoursePage', (index) => {
      this.pageNum = index
      if (this.pageType === 1) {
        this.pageType = 0
        this.$bus.$emit('changeTitleActive', this.pageType)
      }
    })
    // 接收header部分的页面展示跳转事件
    this.$bus.$on('switchPageType', (index) => {
      console.log('页面类型:', index)
      this.pageType = index
      this.$bus.$emit('changeTitleActive', index, true)
    })
  },
  destroyed() {
    this.$bus.$off('openPageWhiteBoard')
    this.$bus.$off('OpenGlobalWhiteBoard')
    this.$bus.$off('nextButton')
    this.$bus.$off('changeCoursePage')
    this.$bus.$off('switchWhiteBoard')
  },
  methods: {}
}
</script>
>

<style lang="scss" scoped>
.class-page-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
</style>
