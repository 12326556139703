var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-container" }, [
    _c("div", { staticClass: "layout-lesson" }, [
      _c("div", {
        staticClass: "student-left-back",
        on: { click: _vm.backToHome },
      }),
      _c("div", { staticClass: "handle-word-wrap font-lesson-details" }, [
        _vm._v(" LESSON " + _vm._s(_vm.lessonNum) + " "),
      ]),
      _c(
        "ul",
        { staticClass: "progressBar" },
        _vm._l(_vm.pageNumTotal, function (item, index) {
          return _c(
            "li",
            { key: index },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: item + "",
                    placement: "bottom",
                  },
                },
                [
                  _c("span", {
                    staticClass: "progressDot",
                    class: {
                      progressDotFinish: item <= _vm.pageNum,
                      progressDotCurrent: item === _vm.pageNum,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.jumpPage(item)
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    !_vm.preView
      ? _c("div", { staticClass: "student-right" }, [
          _c("div", { staticClass: "student-shou" }, [
            _c("div", { staticClass: "student-yuan" }, [
              _c("span"),
              _c("span", { staticClass: "font-lesson-details" }, [
                _vm._v("In class  |  "),
              ]),
              _c("span", { staticClass: "font-lesson-details" }, [
                _vm._v(_vm._s(_vm.countDown)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }