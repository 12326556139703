<template>
  <div class="customScrollbar-box" ref="canvasBox">
    <div class="wrap">
      <canvas
        id="canvas-1"
        class="fl"
        :width="canvasWidth"
        :height="canvasHeight"
        @mousedown="handleMouseDown($event)"
        @mousemove="handleMouseMove($event)"
        @mouseup="handleMouseUp($event)"
        @mouseleave="lock = false"
        :class="{ eraserStyle: drawStatus === 6 }"
        ref="myCanvas"
      >
      </canvas>
      <!-- <input
        placeholder="请输入文字"
        class="text-input"
        :style="{ top: textInputTop + 'px', left: textInputLeft + 'px' }"
        v-show="isShowText"
        @change.enter="textChange($event)"
      /> -->
    </div>
  </div>
</template>

<script>

export default {
  props: {
    displayColorIndex: {
      type: Number,
      require: false,
      default: 0
    },
    cursorStyle: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data() {
    return {
      textInputTop: 0,
      textInputLeft: 0,
      isShowText: false,

      canvasHeight: null,
      canvasWidth: null,
      // 配置参数
      lineWidth: 3, // 画线粗细
      drawColor: '#333', // 基础颜色
      // shadowBlur: 3,
      fontSize: 16, // 文字字体大小
      textContent: '', // 文字内容
      shapeType: 0, // 图形 默认正方形

      nextDrawAry: [],
      middleAry: [],
      preDrawAry: [],

      clickDrag: [],
      lineX: [],
      lineY: [],
      eraserX: [],
      eraserY: [],
      rect: {},
      storage: {},
      triangle: {},
      trianglePoint: {},

      // textArr: [],
      eraserWidth: 30,
      drawStatus: 0
      // 画笔状态 0不可用 1矩形 2圆形  3画线 4文字 5三角形 6橡皮擦
    }
  },
  mounted() {
    this.$nextTick(() => {
      const canvasBox = this.$refs.canvasBox
      this.canvasHeight = canvasBox.getBoundingClientRect().height
      this.canvasWidth = canvasBox.getBoundingClientRect().width
      // 绑定canvas
      const canvas = document.querySelector('#canvas-1')
      this.ctx = canvas.getContext('2d')
      this.initCanvas()
    })

    // -----------------------操作器事件---------------------------

    // 选择画线
    this.$bus.$on('chooseLine', () => {
      this.drawStatus = 3
    })
    // 选择画图形
    this.$bus.$on('chooseShape', (index = 0) => {
      this.drawStatus = index === 0 ? 1 : index === 1 ? 2 : 5
    })
    // 选择添加文字
    this.$bus.$on('chooseText', (val) => {
      this.textContent = ''
      this.drawStatus = 4
      this.isShowText = true
      this.fontSize = val
    })
    //选择橡皮擦
    this.$bus.$on('chooseEraser', () => {
      this.drawStatus = 6
    })
    // 选择颜色
    this.$bus.$on('chooseDrawColor', (val) => {
      this.drawColor = val
    })
    // 选择线条粗细
    this.$bus.$on('chooseLineWidth', (val) => {
      this.lineWidth = val
      this.drawStatus = 3
    })
    // 前进  后退   清空
    this.$bus.$on('controlCanvas', (val) => {
      this.controlCanvas(val)
    })

    // 画布信息交互
    this.$bus.$on('socket_6666002', (data) => {
      console.log(data)

      this.handleReaction(data)
    })
  },
  watch: {
    
    displayColorIndex(value) {
      setTimeout(() => {
        this.drawRectGround(value)
      }, 50)
    },
    cursorStyle(value){
      console.log(value,'value');
      
      if(value){
        this.drawStatus = 6
      }
    }
  },
  beforeDestroy() {
    this.$bus.$off('controlCanvas')
    this.$bus.$off('chooseLine')
    this.$bus.$off('chooseShape')
    this.$bus.$off('chooseText')
    this.$bus.$off('chooseDrawColor')
    this.$bus.$off('chooseLineWidth')
    this.$bus.$off('socket_6666002')
  },
  methods: {
    drawRectGround(index) {
      if (!index) {
        return
      }
      // this.initCanvas();
      if (index === 1) {
        // 黄色
        this.ctx.fillStyle = '#F4D737'
      } else if (index === 2) {
        this.ctx.fillStyle = '#224E96'
      } else if (index === 3) {
        this.ctx.fillStyle = '#CD4C3F'
      }
      console.log(index, '+++++++++++++')
      this.ctx.fillRect(0, 0, this.canvasWidth * 0.9, this.canvasHeight * 0.92)
    },
    handleReaction(data) {
      console.log(data)

      this.clear()
      const img = new Image()
      img.src = data.value.imgBase64 // 设置Base64字符串作为源
      img.onload = () => {
        // 将图像绘制到Canvas
        this.ctx.drawImage(
          img,
          0,
          0,
          data.value.canvasWidth,
          data.value.canvasHeight
        )
        const preData = this.ctx.getImageData(
          0,
          0,
          this.canvasWidth,
          this.canvasHeight
        )
        this.preDrawAry.push(preData)
        this.handleCanvasStep(preData)
      }
    },
    //=----------------------------------------
    handleWidthChange(value) {
      this.canvasWidth = value
      this.controlCanvas('clear')
    },
    // 初始化canvas画布
    initCanvas() {
      this.clear()
      // 空绘图表面进栈
      this.middleAry.push(
        this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight)
      )
    },

    /**
     * @description: 鼠标Down事件:初始化画图起始点
     * @param {*} e
     * @return {*}
     */

    handleMouseDown(e) {
      // 记录开始的画布数据
      this.preDrawAry.push(
        this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight)
      )

      //记录点击的坐标点
      this.lock = true

      let _x = e.offsetX
      let _y = e.offsetY
      switch (this.drawStatus) {
        case 1:
          // 矩形
          this.rect.x = _x
          this.rect.y = _y
          break
        case 2:
          //圆形
          this.storage.x = _x
          this.storage.y = _y
          break
        case 5:
          // 三角形
          this.triangle.x = _x
          this.triangle.y = _y
          break
      }
    },

    // 鼠标移动
    handleMouseMove(e) {
      if (!this.lock) return
      // 每次移动重新画一次
      this.ctx.putImageData(this.preDrawAry[this.preDrawAry.length - 1], 0, 0)

      let _x = e.offsetX
      let _y = e.offsetY
      switch (this.drawStatus) {
        case 3:
          this.lineX.push(_x)
          this.lineY.push(_y)
          this.clickDrag.push(_y)

          this.drawPoint(
            this.lineX,
            this.lineY,
            this.clickDrag,
            this.lineWidth,
            this.drawColor
          )
          break
        case 1:
          this.rect.width = Math.abs(this.rect.x - _x)
          this.rect.height = Math.abs(this.rect.y - _y)
          if (this.rect.x > _x) {
            this.rect.realX = _x
          } else {
            this.rect.realX = this.rect.x
          }
          if (this.rect.y > _y) {
            this.rect.realY = _y
          } else {
            this.rect.realY = this.rect.y
          }

          this.drawRect(
            this.rect.realX,
            this.rect.realY,
            this.rect.width,
            this.rect.height,
            0,
            this.drawColor,
            2
          )
          break
        case 2:
          this.drawEllipse(
            this.storage.x > _x
              ? this.storage.x - Math.abs(this.storage.x - _x) / 2
              : Math.abs(this.storage.x - _x) / 2 + this.storage.x,

            this.storage.y > _y
              ? this.storage.y - Math.abs(this.storage.y - _y) / 2
              : Math.abs(this.storage.y - _y) / 2 + this.storage.y,

            Math.abs(this.storage.x - _x) / 2,
            Math.abs(this.storage.y - _y) / 2,
            2,
            this.drawColor
          )
          break
        case 5:
          this.trianglePoint = {
            topPoint:
              _x > this.triangle.x && _y > this.triangle.y
                ? {
                    x: _x,
                    y: this.triangle.y
                  }
                : {
                    x: this.triangle.x,
                    y: this.triangle.y
                  },
            leftPoint:
              _x > this.triangle.x && _y > this.triangle.y
                ? {
                    x: this.triangle.x,
                    y: _y
                  }
                : {
                    x: _x,
                    y: _y
                  },
            rightPoint:
              _x > this.triangle.x && _y > this.triangle.y
                ? {
                    x: _x + (_x - this.triangle.x),
                    y: _y
                  }
                : {
                    x: 2 * this.triangle.x - _x,
                    y: _y
                  }
          }
          this.drawTriangle(
            this.trianglePoint.topPoint,
            this.trianglePoint.leftPoint,
            this.trianglePoint.rightPoint,
            this.drawColor
          )
          break
        case 6:
          this.eraserX.push(_x)
          this.eraserY.push(_y)
          this.drawEraser(this.eraserX, this.eraserY, this.eraserWidth)
          break
      }
    },

    // 鼠标升起
    handleMouseUp() {
      this.lock = false

      this.rect = {}

      this.storage = {}

      this.lineX = []
      this.lineY = []
      this.clickDrag = []

      this.trianglePoint = {}

      this.eraserX = []
      this.eraserY = []

      this.handleCanvasStep(
        this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight)
      )
      this.changeDrawAry()
    },
    /**
     * @description: 画布进栈逻辑
     * @return {*}
     */
    handleCanvasStep(preData) {
      if (!this.nextDrawAry.length) {
        this.middleAry.push(preData)
      } else {
        this.middleAry = []
        this.middleAry = this.middleAry.concat(this.preDrawAry)
        this.middleAry.push(preData)
        this.nextDrawAry = []
      }
    },
    /**
     * @description: 交互socket发送
     * @return {*}
     */

    changeDrawAry() {
      const data = {
        clickType: 6666002,
        data: {
          value: {
            imgBase64: this.$refs.myCanvas.toDataURL('imgage/png'),
            canvasWidth: this.canvasWidth,
            canvasHeight: this.canvasHeight
          }
        },
        text: '画布信息传递'
      }
      this.$bus.$emit('kids_webSocket_sendInfo', data)
    },
    // 清除画布
    clear() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight) //清除画布，左上角为起点
    },

    //---------------通用画图方法----开始-------------

    // 涂鸦
    drawPoint(x, y, clickDrag, lineWidth, color) {
      for (
        let i = 0;
        i < x.length;
        i++ //循环数组
      ) {
        this.ctx.beginPath()

        if (clickDrag[i] && i) {
          this.ctx.moveTo(x[i - 1], y[i - 1])
        } else {
          this.ctx.moveTo(x[i] - 1, y[i])
        }
        this.ctx.lineWidth = lineWidth
        this.ctx.strokeStyle = color
        this.ctx.lineTo(x[i], y[i])
        this.ctx.closePath()
        this.ctx.stroke()
      }
    },

    // 使用橡皮擦
    drawEraser(x, y, eraserWidth) {
      for (let i = 0; i < x.length; i++) {
        this.ctx.save()
        this.ctx.beginPath()
        this.ctx.arc(
          x[i] + eraserWidth / 2,
          y[i] + eraserWidth / 2,
          eraserWidth / 2,
          0,
          Math.PI * 2
        )
        this.ctx.clip()
        this.ctx.clearRect(x[i], y[i], eraserWidth, eraserWidth)
        this.ctx.restore()
      }
    },
    // 画矩形
    drawRect(x, y, width, height, radius, color, lineWidth) {
      this.ctx.beginPath()
      this.ctx.moveTo(x, y + radius)
      this.ctx.lineTo(x, y + height - radius)
      this.ctx.quadraticCurveTo(x, y + height, x + radius, y + height)
      this.ctx.lineTo(x + width - radius, y + height)
      this.ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width,
        y + height - radius
      )
      this.ctx.lineTo(x + width, y + radius)
      this.ctx.quadraticCurveTo(x + width, y, x + width - radius, y)
      this.ctx.lineTo(x + radius, y)
      this.ctx.quadraticCurveTo(x, y, x, y + radius)
      this.ctx.strokeStyle = color
      this.ctx.lineWidth = lineWidth
      this.ctx.closePath()
      this.ctx.stroke()
    },

    // 画圆形
    drawEllipse(x, y, a, b, lineWidth, color) {
      this.ctx.beginPath()
      // this.ctx.shadowBlur = 2;
      // this.ctx.shadowColor = color;
      this.ctx.ellipse(x, y, a, b, 0, 0, 2 * Math.PI)
      this.ctx.lineWidth = lineWidth
      this.ctx.fillStyle = 'rgba(0,0,0,0)'
      this.ctx.strokeStyle = color
      this.ctx.fill()
      this.ctx.stroke()
    },
    // 画三角形
    drawTriangle(top, left, right, color) {
      this.ctx.beginPath()
      this.ctx.moveTo(top.x, top.y)
      this.ctx.lineTo(left.x, left.y)
      this.ctx.lineTo(right.x, right.y)
      this.ctx.lineWidth = 2
      this.ctx.strokeStyle = color
      this.ctx.closePath()
      this.ctx.stroke()
    },

    // 控制前进 后退  清除画布
    controlCanvas(action) {
      const t = this
      switch (action) {
        case 'prev':
          if (t.preDrawAry.length) {
            t.nextDrawAry.push(t.middleAry[t.preDrawAry.length + 1])
            t.ctx.putImageData(t.preDrawAry.pop(), 0, 0)
            this.changeDrawAry()
          }
          break
        case 'next':
          if (t.nextDrawAry.length) {
            t.preDrawAry.push(
              t.middleAry[t.middleAry.length - t.nextDrawAry.length - 2]
            )
            t.ctx.putImageData(t.nextDrawAry.pop(), 0, 0)
            this.changeDrawAry()
          }
          break
        case 'clear':
          t.ctx.clearRect(0, 0, t.canvasWidth, t.canvasHeight)
          t.preDrawAry = []
          t.nextDrawAry = []
          t.middleAry = [t.middleAry[0]]
          // t.textArr = []
          this.changeDrawAry()
          break
      }
    }

    //---------------通用画图方法----结束-------------

    // textChange(e) {
    //   this.textContent = e.target.value
    //   this.drawStatus = 4
    //   this.isShowText = false
    // }

    // case 4:
    //   // 文字插入
    //   this.textInputTop = _y
    //   this.textInputLeft = _x
    //   // 画一个textarea
    //   this.ctx.font = `${this.fontSize}px bold 黑体`
    //   // 设置颜色
    //   this.ctx.fillStyle = this.drawColor
    //   // 设置水平对齐方式
    //   this.ctx.textAlign = 'center'
    //   // 设置垂直对齐方式
    //   this.ctx.textBaseline = 'middle'
    //   // 绘制文字（参数：要写的字，x坐标，y坐标）
    //   this.ctx.fillText(`${this.textContent}`, _x, _y)
    //   this.textArr.push({
    //     x: _x,
    //     y: _y,
    //     textContent: this.textContent,
    //     color: this.drawColor,
    //     fontSize: this.fontSize
    //   })
    //   break
  }
}
</script>

<style scoped lang="scss">
.customScrollbar-box {
  width: 100%;
  height: 100%;
  z-index: 9999;
  line-height: 1;
}

.wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

#canvas-1 {
  cursor: url('../../../assets/img/02-Toolbar/cursor-brush.svg'), crosshair;
  // cursor: crosshair;
}

#canvas-1.eraserStyle {
  cursor: url('../../../assets/img/02-Toolbar/eraser-active-1.png'), crosshair;

  // cursor: crosshair;
}

.text-input {
  position: absolute;
  font-size: 2rem;
}
</style>
