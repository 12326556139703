import axios from './axios'

// 获取kids课程数据枚举  291：title类型，292：课件等级
export function getKidsCourseEnumData(params) {
  return axios({
    method: 'get',
    url: 'enum/value',
    params
  })
}

// 批量添加课件模板
export function getKidsCourseData(data) {
  return axios({
    method: 'post',
    url: 'kidsCourseware/addKidsCoursewareTemplateBatch',
    data
  })
}

// 添加或者修改课件
export function addOrUpdateKidsCourseware(data) {
  return axios({
    method: 'post',
    url: 'kidsCourseware/addOrUpdateKidsCourseware',
    data
  })
}

// 添加或者修改课件
export function addOrUpdateKidsCoursewareTemplate(data) {
  return axios({
    method: 'post',
    url: 'kidsCourseware/addOrUpdateKidsCoursewareTemplate',
    data
  })
}

// 删除课件
export function deleteKidsCourseware(params) {
  return axios({
    method: 'delete',
    url: 'kidsCourseware/deleteKidsCourseware',
    params
  })
}

// 删除课件模板
export function deleteKidsCoursewareTemplate(params) {
  return axios({
    method: 'delete',
    url: 'kidsCourseware/deleteKidsCoursewareTemplate',
    params
  })
}

// 查询单个页面课件详情
export function getKidsCoursewareDetail(data) {
  return axios({
    method: 'post',
    url: 'kidsCourseware/getKidsCoursewareInfo',
    data
  })
}

// 查询课的课件列表
export function getKidsCoursewareList(data) {
  return axios({
    method: 'post',
    url: 'kidsCourseware/getKidsCoursewareInfo',
    data
  })
}

// 查询课件模板详情
export function getKidsCoursewareTemplateInfo(params) {
  return axios({
    method: 'get',
    url: 'kidsCourseware/getKidsCoursewareTemplateInfo',
    params
  })
}

// 查询课件模板列表
export function getKidsCoursewareTemplateList(data) {
  return axios({
    method: 'post',
    url: 'kidsCourseware/getKidsCoursewareTemplateList',
    data
  })
}

// 根据id和枚举id查询课件内容
export function getKidsCourseDataByEnumId(params) {
  return axios({
    method: 'get',
    url: 'enum/valueByEnumIdAndId',
    params
  })
}

// 添加课件中的某一页
export function addKidsCoursewareContent(data) {
  return axios({
    method: 'post',
    url: 'kidsCourseware/addKidsCoursewareContent',
    data
  })
}

// 修改课件中的某一页
export function updateKidsCoursewareContent(data) {
  return axios({
    method: 'post',
    url: 'kidsCourseware/updateKidsCoursewareContent',
    data
  })
}

// 删除课件中的某一页 
export function deleteKidsCoursewareContent(params) {
  return axios({
    method: 'delete',
    url: 'kidsCourseware/deleteKidsCoursewareContent',
    params
  })
}

 