<template>
  <div class="whiteBoard-container">
    <div class="control-box">
      <div class="control-box-content">
        <div class="control-box-area">
          <div class="select-area-item" v-show="isShapeUse">
            <div class="shape-content">
              <div
                v-for="(item, index) in shapeTypeList"
                :key="index"
                @click="chooseShape(index)"
              >
                <img
                  :src="
                    require(`@/assets/img/02-Toolbar/${item.name}-active.svg`)
                  "
                  :title="item.chinese"
                  v-if="shapeType === index"
                />
                <img
                  :src="require(`@/assets/img/02-Toolbar/${item.name}.svg`)"
                  :title="item.chinese"
                  v-else
                />
              </div>
            </div>
          </div>

          <!-- lineWidth -->
          <div class="select-area-item" v-show="isBrushOpen">
            <div class="line-width-content">
              <span
                class="line-width-item"
                v-for="(item, index) in lineWidthArr"
                :key="index"
                @click="chooseLineWidth(item.value)"
                :style="{ width: item.width, height: item.width }"
                :class="{
                  'line-width-active': item.value === lineWidth
                }"
              ></span>
            </div>
          </div>

          <!-- color -->
          <div class="select-area-item" v-show="isBrushOpen">
            <div class="color" v-show="isBrushOpen || isShapeUse">
              <div
                class="color-area"
                @click="isColorChooseUse = true"
                :style="{ background: drawColor }"
              />
              <transition name="el-zoom-in-top">
                <div class="color-box" v-if="isColorChooseUse">
                  <div
                    class="color-border-box"
                    v-for="(item, index) in predefineColors"
                    :key="index"
                    @click="chooseColor(item)"
                    :class="{ 'active-color': currentColorNum === index }"
                    @mouseenter="currentColorNum = index"
                  >
                    <div
                      class="color-item"
                      :style="{
                        background: item
                      }"
                    ></div>
                  </div>
                </div>
              </transition>
            </div>
          </div>

          <div class="dis-line" v-show="isBrushOpen || isShapeUse">|</div>

          <div class="brush item" @click="handleChangePen">
            <img
              src="@/assets/img/02-Toolbar/tool-brush.svg"
              title="笔刷"
              v-show="!isBrushOpen"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-brush-active.svg"
              title="笔刷"
              v-show="isBrushOpen"
            />
          </div>
          <div class="shape item" @click="handleChooseShape">
            <img
              src="@/assets/img/02-Toolbar/tool-shapes-active.svg"
              title="形状"
              v-show="isShapeUse"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-shapes.svg"
              title="形状"
              v-show="!isShapeUse"
            />
          </div>
          <div class="eraser item" @click="handleEraser">
            <img
              src="@/assets/img/02-Toolbar/tool-eraser.svg"
              title="橡皮擦"
              v-if="!isEraserUse"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-eraser-active.svg"
              title="橡皮擦"
              v-else
            />
          </div>
          <!-- <div class="prev item" @click="handleControl('prev')">
            <img
              src="@/assets/img/02-Toolbar/tool-undo.svg"
              title="上一步"
              v-if="!isPrev"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-undo-active.svg"
              title="上一步"
              v-else
            />
          </div>
          <div class="next item" @click="handleControl('next')">
            <img
              src="@/assets/img/02-Toolbar/tool-redo.svg"
              title="下一步"
              v-if="!isNext"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-redo-active.svg"
              title="下一步"
              v-else
            />
          </div>
          <div class="clear item" @click="handleControl('clear')">
            <img
              src="@/assets/img/02-Toolbar/tool-delete.svg"
              title="清除"
              v-if="!isClear"
            />
            <img
              src="@/assets/img/02-Toolbar/tool-delete-active.svg"
              title="清除"
              v-else
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { sendMessage_1000410 } from "@/common/js/socketSendMessage";

export default {
  data() {
    return {
      // isPointClick: false, // 交互按钮
      pageType: 1, // 0 画板  1全局

      drawColor: '#333', //画笔颜色
      lineWidth: 3, // 画笔粗细
      textFontSize: 24, // 默认字体大小 16px
      isClear: false,
      isEraserUse: false,
      isPrev: false,
      isNext: false,
      isColorChooseUse: false,
      isBrushOpen: false, // 笔刷选择器,控制canvas打开或者关闭
      isShapeUse: false,
      isInsertText: false,
      isMouseClick: true,
      shapeType: 0, // 图形种类, 0 方形 1  圆形 2 三角形

      isShowSelectBox: true,
      currentColorNum: 2,
      predefineColors: [
        // 预设颜色
        '#333',
        '#F4D737',
        '#224E96',
        '#CD4C3F',
        '#F7F4E0',
        '#ffffff',
        '#F5F5F5',
        '#CFCECB'
      ],
      lineWidthArr: [
        {
          value: 1,
          width: '4px'
        },
        {
          value: 3,
          width: '8px'
        },
        {
          value: 5,
          width: '12px'
        }
      ],
      shapeTypeList: [
        {
          name: 'square',
          type: 0,
          chinese: '方形'
        },
        {
          name: 'circle',
          type: 1,
          chinese: '圆形'
        },
        {
          name: 'triangle',
          type: 2,
          chinese: '三角形'
        }
      ],
      clickDrawLineSocketInfo: {},
      clickMouseSocketInfo: {}
    }
  },
  mounted() {
    // this.$bus.$emit("isStartDraw", true);

    /**
     * toolBar按钮的socket交互 开始
     */

    // 鼠标点击交互 1000501
    this.$bus.$on('toolBarMouseClick', () => {
      this.handleClickMouse('socket', true)
    })

    // 画线条点击交互 1000502
    this.$bus.$on('toolBarDrawLine', () => {
      this.handleDrawLine('socket', true)
    })
  },
  beforeDestroy() {
    // this.$bus.$emit('isStartDraw', false)
    clearTimeout(this.timer)

    this.$bus.$off('toolBarMouseClick')
    this.$bus.$off('toolBarDrawLine')
  },
  destroyed() {
    this.$bus.$off('toolBarInteraction')
    this.isBrushOpen = false
  },
  methods: {
    // 打开鼠标选择
    handleClickMouse(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMouseSocketInfo = {
          value: Math.random()
        }
      }
      this.$bus.$emit('controlCanvas', 'clear')
      // this.$bus.$emit("canvasON", false);
      this.handleGlobalBoard(false, 0)

      this.initToolBarButton()
      this.isMouseClick = true
    },

    //  处理画线条
    handleDrawLine(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickDrawLineSocketInfo = {
          value: Math.random()
        }
      }
      this.initToolBarButton()
      this.isBrushOpen = true
      console.log('用笔状态:', this.isBrushOpen)
      this.handleGlobalBoard(true)
      setTimeout(() => {
        this.$bus.$emit('chooseLine', true)
      }, 50)
    },

    initBoard() {
      this.drawColor = '#333' //画笔颜色
      this.lineWidth = 3 // 画笔粗细
      this.textFontSize = 24 // 默认字体大小 16px
      this.isClear = false
      this.isEraserUse = false
      this.isPrev = false
      this.isNext = false
      this.isColorChooseUse = false
      this.isBrushOpen = false // 笔刷选择器控制canvas打开或者关闭
      this.isShapeUse = false
      this.isInsertText = false
      this.isMouseClick = true
      this.shapeType = 0 // 图形种类, 0 方形 1  圆形 2 三角形
      // }
    },
    // 处理全局画板
    handleGlobalBoard(val) {
      console.log('页面类型', this.pageType) // 0 全局  2 白板页面
      if (!this.pageType) {
        this.$bus.$emit('openPageWhiteBoard')
      } else {
        this.$bus.$emit('OpenGlobalWhiteBoard', val)
      }
    },
    // 初始化按钮展示
    initToolBarButton() {
      this.isMouseClick = false
      this.isBrushOpen = false
      this.isInsertText = false
      this.isShapeUse = false
      this.isEraserUse = false
      this.isPointClick = false
      this.isShowSelectBox = false
      this.isColorChooseUse = false
    },

    // 处理形状选择
    handleChooseShape() {
      this.isShapeUse = true
      this.shapeType = 0

      this.isMouseClick = false
      this.isBrushOpen = false
      this.isInsertText = false
      this.isEraserUse = false
      this.isShowSelectBox = false
      setTimeout(() => {
        this.$bus.$emit('chooseShape')
      }, 50)
    },
    //   打开画笔选择
    handleChangePen() {
      this.isBrushOpen = true
      this.isEraserUse = false
      this.isShowSelectBox = false
      this.isMouseClick = false
      this.isInsertText = false
      this.isShapeUse = false

      setTimeout(() => {
        this.$bus.$emit('chooseLine')
      }, 50)
    },

    // 使用橡皮擦
    handleEraser() {
      this.isEraserUse = true
      this.isMouseClick = false
      this.isBrushOpen = false
      this.isInsertText = false
      this.isShapeUse = false
      this.isShowSelectBox = false
      this.$bus.$emit('chooseEraser')
    },

    // 处理清除 前进 后退
    handleControl(val) {
      this.isPrev = 'prev' === val
      this.isNext = 'next' === val
      this.isClear = 'clear' === val
      this.$bus.$emit('controlCanvas', val)
      this.timer = setTimeout(() => {
        this.isPrev = false
        this.isNext = false
        this.isClear = false
      }, 300)
    },

    // 选择颜色
    chooseColor(val) {
      this.$bus.$emit('changeColor', val)
      this.isColorChooseUse = false
      this.drawColor = val
      this.$bus.$emit('chooseDrawColor', val)
    },

    // 选择线条粗细
    chooseLineWidth(val) {
      this.$bus.$emit('chooseLineWidth', val)
      this.lineWidth = val
    },

    // 选择画图形状
    chooseShape(index) {
      this.shapeType = index
      this.$bus.$emit('chooseShape', index)
    }
  }
}
</script>

<style scoped lang="scss">
.whiteBoard-container {
  width: 100%;
  height: 100%;
  // display: flex;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  .control-box {
    width: 100%;
    height: 100%;

    .control-box-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .control-box-area {
        height: 100%;
        background-color: #fff;
        border-radius: 25px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0 15px;
        .dis-line {
          color: #224e96;
          text-align: center;
          line-height: 35px;
        }

        .select-area-item {
          height: 100%;
          display: flex;
          justify-content: space-between;

          .color {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .color-border {
              width: 16px;
              height: 16px;
              border: 2px solid #224e94;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .color-area {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              cursor: pointer;
            }
          }
          .line-width-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            .line-width-item {
              border-radius: 50%;
              background: black;
              cursor: pointer;
              margin: 0 5px;
            }
          }
          .shape-content {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease-out;

            img {
              margin: 0 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 15px;
              height: 15px;
              cursor: pointer;
            }
          }
        }
      }

      .item {
        // flex: 0 0 auto;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }

        span {
          cursor: pointer;
        }
      }

      // 笔顺描边
      .line-width-active {
        box-shadow: 0px 0px 2px 3px orange;
      }

      // 切换颜色
      .color-box {
        position: absolute;
        top: 35px;
        left: 5px;
        width: 90px;
        // height: 35px;
        padding: 8px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0 0 1px 1px rgb(238, 236, 236);
        flex-wrap: wrap;
        z-index: 100;

        .color-border-box {
          margin: 0 2px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 0.5px solid #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2px;
          cursor: pointer;
        }

        .active-color {
          border: 0.5px solid #224e94;
        }

        .color-item {
          width: 14px;
          height: 14px;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
