<template>
  <div class="CHAT">
    <!-- main -->
    <div
      ref="messageList"
      @dragover.prevent.stop
      @dragleave.prevent.stop
      @dragenter.prevent.stop
      @drop.prevent="dropImg"
      class="main customScrollbar"
    >
      <MessageKids
        v-for="(message, index) in messageList"
        :key="index"
        :messageData="message"
      ></MessageKids>
    </div>
    <div class="line"></div>
    <!-- foot -->
    <div class="foot">
      <!-- <newImg :src="require('@/assets/img/course/interaction/add_blue.png')" class="add" @click.native="addImage()"></newImg> -->
      <div class="uploadImg" @click="addImage()">
        <svg
          id="Icon_ionic-ios-images"
          data-name="Icon ionic-ios-images"
          xmlns="http://www.w3.org/2000/svg"
          width="28.434"
          height="28.424"
          viewBox="0 0 28.434 28.424"
        >
          <path
            id="路径_32313"
            data-name="路径 32313"
            d="M17.752,13.99a.3.3,0,0,0-.489-.044L11.432,21.7a.3.3,0,0,0,.2.489l10.223.888a.3.3,0,0,0,.286-.451Z"
            transform="translate(-3.138 -3.379)"
            fill="#fff"
          />
          <path
            id="路径_32314"
            data-name="路径 32314"
            d="M25.588,18.553a.3.3,0,0,0-.489-.044L23.526,20.1a.3.3,0,0,0-.032.343l1.612,3.141a.3.3,0,0,0,.235.146l2.849.247a.3.3,0,0,0,.286-.451Z"
            transform="translate(-4.317 -3.824)"
            fill="#fff"
          />
          <path
            id="路径_32315"
            data-name="路径 32315"
            d="M26.42,15.454a1.5,1.5,0,1,0-1.5-1.783A1.512,1.512,0,0,0,26.42,15.454Z"
            transform="translate(-4.457 -3.243)"
            fill="#fff"
          />
          <path
            id="路径_32316"
            data-name="路径 32316"
            d="M29.1,8.424l-4.175-.362-.387-4.252A1.714,1.714,0,0,0,22.67,2.256L3.817,3.957A1.721,1.721,0,0,0,2.256,5.822l1.7,18.815a1.714,1.714,0,0,0,1.872,1.555l1-.089-.1,1.06A1.716,1.716,0,0,0,8.3,29.022l18.866,1.644A1.721,1.721,0,0,0,29.029,29.1l1.65-18.821A1.729,1.729,0,0,0,29.1,8.424ZM5.778,19.3a.437.437,0,0,1-.47-.387L4.166,6.222a.427.427,0,0,1,.387-.463L22.271,4.166a.437.437,0,0,1,.47.387l.3,3.351-12.8-1.123A1.721,1.721,0,0,0,8.38,8.342L7.434,19.148Zm22.984-8.6-1.11,12.691a.432.432,0,0,1-.463.393l-1.18-.1L9.465,22.239a.432.432,0,0,1-.393-.463l.241-2.786.869-9.906a.432.432,0,0,1,.463-.393l12.564,1.1,1.885.165,3.274.286A.414.414,0,0,1,28.762,10.7Z"
            transform="translate(-2.25 -2.249)"
            fill="#fff"
          />
        </svg>
      </div>
      <input
        class="chat_input"
        type="text"
        placeholder="Type a message…"
        v-model="message_text"
        @keyup.enter="sendMessageText"
        maxlength="1000"
        ref="count"
      />
      <!--span class="chat_input_tips">You can also enter {{numberlength}} characters</span-->
      <!-- <newImg :src="require('@/assets/img/course/interaction/emoji.png')"></newImg> -->
      <!-- <newImg :src="require('@/assets/img/course/interaction/audio_2.png')"></newImg> -->
    </div>

    <NewSelect2
      ref="select"
      class="select"
      :listData="listData"
      @selectClick="selsecChange"
      :activeIndex="networkIndex"
    ></NewSelect2>
    <!-- 图片选择input -->
    <input
      v-show="false"
      ref="input_image"
      type="file"
      accept="image/*"
      @change="input_image_change"
    />
  </div>
</template>

<script>
// api
import {
  getUserChatRecord,
  saveUserChatRecord,
  uploadChatRecordFile,
} from "@/assets/js/axios/api";
// socket sendMessage
import {
  sendMessage_10005,
  sendMessage_10010001,
} from "@/utils/socketSendMessage";

let startId = 0;

let icon_video = require("@/assets/img/19-Dashboard/icon-video.png");
let icon_video_close = require("@/assets/img/19-Dashboard/icon-video-off.png");
// let icon_video_hover = require("@/assets/img/19-Dashboard/icon-video-on.png");

export default {
  components: {
    // NewImg: () => import("../NewImg"),
    // kgPy: () => import("@/views/components/kg_py"),
    MessageKids: () => import("../MessageKids"),
    NewSelect2: () => import("../NewSelect2"),
  },
  computed: {
    // 视频icon
    videoIcon() {
      let icon;
      switch (this.roomState) {
        case 2:
          icon = icon_video;
          break;
        case 1:
          icon = icon_video_close;
          break;
      }
      return icon;
    },
  },
  data() {
    return {
      // 还可输入字数长度
      numberlength: "1000",
      // 课程数据
      courseData: {
        studenVOList: [],
      },
      showPy: true,
      message_text: "",
      messageList: [],
      listData: [
        {
          des: "腾讯",
          value: "线路一",
          code: "tx",
        },
        {
          des: "声网",
          value: "线路二",
          code: "sw",
        },
      ],
      networkIndex: 0,
      /**
       * 房间状态
       * 1  未进入视频房间
       * 2  已进入视频房间
       * **/
      roomState: 1,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    dropImg(e) {
      let fileData = e.dataTransfer.files[0];
      let type = fileData.type.split("/")[0]; //限制只能上传图片,未做限制多张图片限制，待确认
      if (type == "image") {
        let formData = new FormData();
        // console.log(fileData)
        formData.append("uploadFile", fileData);
        formData.append("fileType", 1);
        uploadChatRecordFile(formData).then((res) => {
          if (res.code == 200) {
            this.sendMessage_image(res.data);
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "仅支持图片类型文件",
        });
      }
    },
    /**
     * api
     * **/
    // 获取
    getUserChatRecord() {
      getUserChatRecord({
        calendarEventId: this.courseData.calendarEventId,
        startId: startId,
      }).then((res) => {
        // console.log(res.data);
        if (res.code == 200 && res.data.length) {
          // this.messageList = [];

          if (startId < res.data[res.data.length - 1].id) {
            startId = res.data[res.data.length - 1].id;
            for (let [key, val] of res.data.entries()) {
              this.addMessage(val);
            }
          }
        }
      });
    },
    // 保存用户聊天记录
    saveUserChatRecord(requestData) {
      saveUserChatRecord(requestData).then((res) => {
        // console.log(res.data);
        if (res.code == 200) {
          startId = res.data.id;
          // sendMessage_10005(res.data.chatContent);
          sendMessage_10005();
          this.addMessage(res.data);
          this.message_text = "";
        }
      });
    },

    // 开关拼音点击
    kg_pyClick() {
      this.showPy = !this.showPy;
      this.after_kgClick();
    },
    after_kgClick() {
      sendMessage_10010001({
        showPy: this.showPy,
      });
    },

    /** 实时音视频 start **/
    realTimeVideo_enterRoom() {
      this.roomState = 2;
      this.$bus.$emit("realTimeVideo_enterRoom");
    },
    // 进入或退出房间
    realTimeVideo_enterLeaveRoom() {
      if (this.roomState == 1) {
        let nowTime = new Date().getTime();
        let minute_2 = 2 * 60 * 1000;
        if (nowTime + minute_2 < this.courseData.startTime) {
          this.$message({
            type: "warning",
            message:
              "The video function can only be turned on two minutes before the class starts",
          });
        } else {
          this.roomState = 2;
          this.$bus.$emit("realTimeVideo_enterRoom");
        }
      } else if (this.roomState == 2) {
        this.roomState = 1;
        this.$bus.$emit("realTimeVideo_leaveRoom");
      }
    },
    /** 实时音视频 end **/

    // 网络切换
    networkClick(event) {
      let nowTime = new Date().getTime();
      let minute_2 = 2 * 60 * 1000;
      if (nowTime + minute_2 < this.courseData.startTime) {
        this.$message({
          type: "warning",
          message:
            "The video function can only be turned on two minutes before the class starts",
        });
        return false;
      }
      // console.log(event)
      let select = this.$refs.select.$el;

      let window_height = window.innerHeight;
      let window_width = window.innerWidth;
      let mouse_x = event.clientX;
      let mouse_y = event.clientY;

      // console.log(select.style.display)
      if (select.style.display == "block") {
        select.style.display = "none";
      } else {
        select.style.display = "block";
        select.style.top = `${mouse_y + 20}px`;
        select.style.left = `${mouse_x + -select.clientWidth / 2}px`;
      }
    },
    selsecChange(list, index) {
      let select = this.$refs.select.$el;
      select.style.display = "none";
      this.networkIndex = index;
      this.$emit("selsecChange", list.code);
      this.roomState = 2;
    },
    // 添加图片按钮点击
    addImage() {
      let element = this.$refs.input_image;
      element.value = "";
      element.click();
    },
    input_image_change(event) {
      let file = event.target.files[0];
      let formData = new FormData();
      formData.append("uploadFile", file);
      formData.append("fileType", 1);
      uploadChatRecordFile(formData).then((res) => {
        if (res.code == 200) {
          this.sendMessage_image(res.data);
        }
      });
    },
    // 发送文本消息
    sendMessageText() {
      console.log("发送+++++++++++");
      let userData = JSON.parse(localStorage.userData);
      // console.log(userData);
      // this.courseData.calendarEventId = this.$route.params.id;
      if (this.message_text) {
        let requestData = {
          calendarEventId: this.courseData.calendarEventId,
          chatContent: this.message_text,
          contentType: 1,
          photo: userData.userSetting.photo,
        };
        this.saveUserChatRecord(requestData);
      }
    },
    // 发送图片消息
    sendMessage_image(imageUrl) {
      let userData = JSON.parse(localStorage.userData);
      if (imageUrl) {
        let requestData = {
          calendarEventId: this.courseData.calendarEventId,
          chatContent: imageUrl,
          contentType: 2,
          photo: userData.userSetting.photo,
        };
        this.saveUserChatRecord(requestData);
      }
    },
    // 添加消息
    addMessage(data) {
      this.messageList.push(data);
      let userData = JSON.parse(localStorage.userData);
      this.messageList.map((val) => {
        if (val.userId === userData.userSetting.userId) {
          val.isMe = true;
        } else {
          val.isMe = false;
        }
      });
      let messageList = this.$refs.messageList;
      setTimeout(() => {
        messageList.scrollTop = messageList.scrollHeight;
      }, 300);
    },
    // 数据初始化、
    initData() {
      this.courseData.calendarEventId = this.$route.params.calendarEventId;
      // this.courseData = course;
      this.getUserChatRecord();
    },
    // 事件中心绑定事件
    socket_10005001(data) {
      // console.log(data)
      let messageData = {
        contentType: 99,
        chatContent: data.text,
      };
      this.addMessage(messageData);
    },
  },
  mounted() {
    // 注册聊天消息获取
    this.$bus.$on("socket_10005", this.getUserChatRecord);
    // 注册系统消息获取
    this.$bus.$on("socket_10005001", this.socket_10005001);
  },
  beforeDestroy() {
    startId = 0;
    this.$bus.$off("socket_10005");
    this.$bus.$off("socket_10005001");
  },
  watch: {
    message_text: function () {
      var _this = this;
      var _sum = 1000;
      _this.numberlength = _sum - _this.$refs.count.value.length;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .video img {
  width: 100%;
}
.CHAT {
  //  border-radius: 10px;
  overflow: hidden;
  font-weight: 600;
  background-color: #163567;
  display: flex;
  flex-direction: column;
  .head {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    .left {
      display: flex;
      align-items: center;
      max-width: 70%;
      overflow: hidden;
      .new_img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .new_img {
        cursor: pointer;
      }
      .kg_py {
        margin-right: 10px;
        width: 25px;
        height: 25px;
      }
      .video {
        margin-right: 10px;
        width: 25px;
        height: 15px;
        &.close {
          width: 30px;
          height: 20px;
        }
      }
      .phone {
        margin-right: 10px;
        width: 17px;
        height: 17px;
      }
      .network {
        width: 20px;
        height: 20px;
      }
    }
  }
  .main {
    flex: 1;
    height: 100px;
    width: 100%;
    background-color: #163567;
    padding: 20px;
    box-sizing: border-box;
  }
  .line {
    width: 80%;
    height: 1px;
    background: #4b6388;
    margin-left: 10%;
  }
  .foot {
    padding-top: 10px;
    background-color: #163567;
    width: 80%;
    margin-left: 10%;
    height: 70px;
    // display: flex;
    // justify-content: space-evenly;
    // align-items: center;
    .uploadImg {
      padding: 0 10px;
    }
    .chat_input {
      color: #fff;
      // width: 60%;
      width: 80%;
      height: 40px;
      // border: 1px solid #F1F1F0;
      border-radius: 4px;
    }
    .new_img {
      cursor: pointer;
      width: 25px;
      height: 25px;
    }
    .add {
      padding-left: 3px;
      // padding-right: 3px;
      width: 15px;
      height: 15px;
    }
    .chat_input_tips {
      font-size: 12px;
      color: #fff;
    }
  }
  .select {
    display: none;
  }
}
</style>
