var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "class-page-container" }, [
    _vm.pageType === 0 && _vm.componentsList.length
      ? _c(
          "div",
          { staticClass: "display-page" },
          [
            _vm._l(_vm.componentsList, function (item, index) {
              return [
                index + 1 === _vm.pageNum
                  ? _c(item.component, {
                      key: index,
                      tag: "component",
                      staticClass: "display-page",
                      attrs: { componentProps: item.componentProps },
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.pageType === 2
      ? _c("div", { staticClass: "display-page test" }, [_c("WhiteBoard")], 1)
      : _vm._e(),
    _vm.globalWhiteB && _vm.pageType !== 2
      ? _c("div", { staticClass: "whiteBoard-box" }, [_c("WhiteBoard")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }