export const webSocketDefault = {
    data() {
        return {
            courseData: {},
            socketCode: '',
            socket: null,
        }
    },
    created() {
        // 初始化webSocket
        console.log("socketmixins已经引入");
        this.courseData.calendarEventId = this.$route.params.calendarEventId
        this.socketInit()
    },
    methods: {
        // websocket init
        socketInit() {
            // socket地址
            const socketurl = "wss://api.nihaocafe.com/SmartLingo/communication";
            if (typeof WebSocket === "undefined") {
                this.$message({
                    type: "error",
                    message: "您的浏览器不支持socket",
                });
            } else {
                // socket实例化
                let user = JSON.parse(localStorage.userData);
                let url = `${socketurl}/${user.userSetting.userId}/${this.courseData.calendarEventId}`;

                this.socket = new WebSocket(url);
                console.log("socket实例化", url);

                //  监听open事件
                this.socket.onopen = this.open;
                // 监听error事件
                this.socket.onerror = this.erroe;
                // 监听close事件
                this.socket.onclose = this.close;
                // 监听message消息
                this.socket.onmessage = this.getMessage;
            }
        },
        open() {
            // eventsBus注册发送消息事件
            this.$bus.$on("kids_webSocket_sendInfo", (data) => {
                this.sendMessage(data)
            });
            console.log("socket连接成功");
            let userData = JSON.parse(localStorage.userData);
            console.log(typeof userData);
            // sendMessage_10005001({
            //     text: `${userData.userSetting.nickname} JOINED THE CLASSROOM`,
            // });
            // 判断是否为老师，是则调用老师签到接口
            // updateTeacherSignedTime({
            //   calendarEventId: this.courseData.calendarEventId,
            // }).then((res) => {
            //   // console.log(res);
            //   console.log("老师签到");
            // });
        },
        erroe() {
            console.log("连接错误");
            // this.websocket_reconnet();
        },
        close() {
            // 解绑
            this.$bus.$off("kids_webSocket_sendInfo");
            console.log("socket已经关闭", this.socketCode);
            if (this.socketCode != "leave") {
                // this.websocket_reconnet();
            }
        },
        // socket发送信息
        sendMessage(params) {
            console.log(params);
            params.classroomid = this.courseData.calendarEventId;
            this.socket.send(JSON.stringify(params));
        },
    },
}