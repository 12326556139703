var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "PARTICOPANTS" }, [
    _c("div", { staticClass: "head" }, [
      _c("div", { staticClass: "left font_two fontWeight_regular" }, [
        _c("p", [_vm._v(_vm._s(_vm.head_left_text))]),
      ]),
      _c("div", { staticClass: "right" }),
    ]),
    _c(
      "div",
      { staticClass: "mainContent customScrollbar" },
      _vm._l(_vm.peopleList, function (people, index) {
        return _c("div", { key: index, staticClass: "peopleCont" }, [
          _c(
            "div",
            { staticClass: "someInfo" },
            [
              _c("NewImg", {
                staticClass: "people_head",
                attrs: { src: people.photo },
              }),
              _c("p", { staticClass: "font_zero fontWeight_regular" }, [
                _vm._v(_vm._s(people.nickname)),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "optionGroup" },
            [
              _c("NewImg", {
                staticClass: "icon_video",
                attrs: {
                  src: people.type_video
                    ? people.icon_video
                    : people.icon_video_close,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.option_video(people, index)
                  },
                },
              }),
              _c("NewImg", {
                staticClass: "icon_video",
                attrs: {
                  src: people.type_phone
                    ? people.icon_phone
                    : people.icon_phone_close,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.option_phone(people, index)
                  },
                },
              }),
            ],
            1
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }