<template>
  <div class="preview-layout-container" v-if="lessonInfo">
    <div class="layout-header">
      <CourseHeader
        :lessonNum="lessonInfo.lesson"
        :pageNum="pageNum"
        :pageNumTotal="lessonInfo.totalPage"
        @jumpTo="changePage"
        :authority="AUTH"
        :preView="true"
      />
    </div>
    <div class="layout-content">
      <div class="layout-content-left">
        <!-- page 标题 -->
        <div class="layout-content-left-header">
          <!-- 课程标题 -->
          <div class="page-title-box">
            <PageTitle
              :pageIndex="pageNum"
              @displayWhichPage="showPage"
              :pageTitleList="lessonInfo.pageTitleArray"
              :authority="AUTH"
              :preView="true"
            />
          </div>

          <div class="control-box">
            <div class="page-turning">
              <div
                class="previous"
                @click="prevClick"
                :style="{ visibility: pageNum > 1 ? 'visible' : 'hidden' }"
              ></div>
              <div
                class="nextPrevious"
                @click="nextClick"
                :style="{
                  visibility:
                    pageNum < lessonInfo.totalPage ? 'visible' : 'hidden',
                }"
              ></div>
            </div>
          </div>
        </div>
        <!-- 课程主体部分 -->
        <div class="layout-content-left-display">
          <div class="shade-box" v-if="!AUTH"></div>
          <CourseContent
            :componentsList="courseComponents"
            v-if="courseComponents.length"
          />
        </div>
      </div>
      <div class="layout-content-right-lessonList">
        <div
          v-for="(item, index) in lessonList"
          :key="index"
          class="nav_lesson"
          :class="{ active: currentLessonIndex === item.kidsLessonId }"
          @click="jumpLesson(item)"
        >
          <div class="nav_lesson_title">LESSON{{ item.kidsLessonNo }}</div>
          <p
            class="nav_lesson_status red_color"
            v-if="currentLessonIndex === item.kidsLessonId"
          >
            CURRENT
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CourseHeader from "@/components/Course/Header";
import PageTitle from "@/components/Course/PageTitle";
import CourseContent from "../CourseDashboard/CourseContent.vue";

import {
  getKidsCoursewareDetail,
  getKidsCourseDataByEnumId,
} from "@/assets/js/axios/coursewareEditor.js";

import { getPrepareLessonContent } from "@/assets/js/axios/api.js";

import { getPreviewCoursewareListByCoursewareId } from "@/assets/js/axios/kidsApi.js";

export default {
  name: "Preview",
  components: {
    CourseHeader,
    PageTitle,
    CourseContent,
  },
  data() {
    return {
      changePageBtn: 0,
      changePageNum: 0,
      pageNum: 1,
      controlWriteboard: false,
      lessonInfo: null,
      sendSocketInfo: {},
      isShowTeacherMouse: false,
      courseComponents: [],
      AUTH: true,
      lessonList: [],
      currentLessonIndex: null,
    };
  },
  computed: {},
  watch: {
    $route: {
      async handler() {
        await this.getPrepareLessonContentFtn();
        await this.initCourseData();
      },
      deep: true,
    },
    pageNum(index) {
      this.$bus.$emit("changeCoursePage", index);
    },
  },
  mounted() {},

  beforeDestroy() {},
  async created() {
    await this.getPrepareLessonContentFtn();
    await this.initCourseData();

    // this.$store.state.toolBar.isTeacherPoint = false;
  },

  methods: {
    jumpLesson(item) {
      const { calendarEventId, kidsLessonNo, kidsLessonId } = item;
      this.$router.push({
        path: `/course/preview/calendarEventId=${calendarEventId}/lesson${kidsLessonNo}/${kidsLessonId}`,
      });
    },
    async getPrepareLessonContentFtn() {
      const { data, code } = await getPrepareLessonContent({
        calendarEventId: Number(this.$route.params.calendarEventId),
      });
      if (code === 200) {
        // console.log(data, "data");

        // this.courseComponents = data.components;
        // this.AUTH = data.AUTH;
        const lessonId = Number(this.$route.params.lessonId);
        this.lessonList = data;
        this.currentLessonIndex = lessonId;
        const matchingLesson = this.lessonList.find(
          (lesson) => lesson.kidsLessonId === this.currentLessonIndex
        );
        if (matchingLesson) {
          this.coursewareId = matchingLesson.coursewareId;
        } else {
          this.coursewareId = null;
        }
        // this.pageNum = data.pageNum;
        // this.lessonInfo = data.lessonInfo;
        // this.initComponentsList(data.contentList);
      }
    },
    /**
     * @description: 初始化课件数据
     * @return {*}
     */
    async initCourseData() {
      this.pageNum = 1;
      //   const lessonId = Number(this.$route.params.lessonId);
      //   const { chapterId, levelId } = await this.checkLessonId(lessonId);
      await this.getCourseData(this.coursewareId);
    },

    /**
     * @description: 根据lessonId 获取chapterId 和 levelId
     * @param {*} lessonId
     * @return {*}
     */
    async checkLessonId(lessonId) {
      if (!lessonId) {
        return;
      }
      try {
        // 第一次请求，获取 bassId
        const { code, data } = await getKidsCourseDataByEnumId({
          enumId: 292,
          id: lessonId,
        });
        if (code === 200) {
          const { parentId: id } = data && data[0];
          // 第一次查找的 parentId 作为 chapterId
          // 使用 chapterId 再次查找 levelId
          const { code: levelCode, data: levelData } =
            await getKidsCourseDataByEnumId({
              enumId: 292,
              id,
            });
          if (levelCode === 200) {
            const levelId = levelData && levelData[0].parentId;
            // 返回 chapterId 和 levelId
            return { id, levelId };
          }
        }
      } catch (error) {
        console.error("获取课程数据失败:", error);
      }
    },

    /**
     * @description: 获取课程信息
     * @return {*}
     */
    async getCourseData(coursewareId) {
      const { data, code } =
        (await getPreviewCoursewareListByCoursewareId({
          coursewareId,
        })) || {};
      if (code === 200) {
        console.log(data);
// return

        // const { title: lesson, contentList } = data;
        const allPages = data.filter((item) => item.content);
        // 初始化课程页面信息
        this.lessonInfo = {
          lesson: this.$route.params.lessonNo,
          totalPage: allPages.length,
          pageTitleArray: allPages.map((item) => {
            return {
              title: item.templateTypeName,
              // isTheme: item.fieldName === '4_CourseSyllabus'
              isTheme: false,
            };
          }),
        };
        // 初始化组件信息
        const formatList = allPages.map((item) => {
          return {
            ...item,
            content: JSON.parse(item.content) || [],
          };
        });
        this.initComponentsList(formatList);
      }
    },
    /**
     * @description: 初始化组件列表
     * @param {*} list
     * @return {*}
     */
    async initComponentsList(list) {
      const allComponents = await Promise.all(
        list.map((item) => this.loadComponent(item.fieldName))
      );

      this.courseComponents = allComponents.map((component, index) => {
        let componentProps = {};
        list[index].content.forEach((ele) => {
          componentProps[ele.fieldName] = ele.value;
        });
        return {
          component,
          componentProps,
        };
      });
    },
    /**
     * @description: 获取组件列表和数据
     * @param {*} componentName
     * @return {*}
     */
    loadComponent(componentName) {
      return new Promise((resolve) => {
        require([
          "../CoursewareEditor/components/GameAndPage/" + componentName,
        ], (component) => {
          resolve(component.default || component);
        });
      });
    },
    // 切换页码
    changePage(value, type) {
      if (type != 1) {
        this.changePageNum++;
      }
      this.pageNum = value;
    },
    // 切换白板和课程页面
    showPage(value) {
      this.$bus.$emit("switchPageType", value);
    },
    nextClick() {
      if (!this.AUTH) {
        return;
      }
      this.pageNum++;
      this.changePageBtn++;
    },
    prevClick() {
      if (!this.AUTH) {
        return;
      }
      this.pageNum--;
      this.changePageBtn++;
    },
  },
};
</script>
>

<style lang="scss" scoped>
.preview-layout-container {
  width: 100%;
  height: 100%;
  flex-direction: row;
  position: relative;

  .shade-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }

  .layout-content {
    width: 100%;
    height: calc(100vh - 54px);
    background-color: #224e96;
    background-image: url("../../assets/img/03-Backgrounds/background.svg");
    display: flex;
    box-sizing: border-box;

    .layout-content-left {
      width: calc(65% - 4px);
      height: calc(100% - 4px);
      padding-left: 4px;
      padding-bottom: 4px;

      .layout-content-left-header {
        width: 100%;
        height: 7%;
        min-height: 60px;
        display: flex;

        .page-title-box {
          flex: 2;
          margin-left: 50px;
          display: flex;
          position: relative;
        }

        .control-box {
          flex: 3;
          display: flex;
          position: relative;
          justify-content: flex-end;
          align-items: center;
          margin-top: 0px;
          margin-right: 25px;

          // 修改操作板占位样式
          .white-board-control {
            flex: 4;
            height: 35px;
            margin-right: 20px;
          }

          .page-turning {
            flex: 1;
            top: 22%;
            right: 0;
            display: flex;
            align-self: center;
            justify-content: flex-end;

            .previous:hover,
            .nextPrevious:hover {
              cursor: pointer;
            }

            .previous {
              width: 42px;
              height: 42px;
              background-image: url("../../assets/img/04-Buttons/button-previous-page.svg");
              margin-right: 10px;
            }

            .previous:hover {
              background-image: url("../../assets/img/04-Buttons/button-previous-page-hover.svg");
            }

            .nextPrevious {
              width: 42px;
              height: 42px;
              background-image: url("../../assets/img/04-Buttons/button-next-page.svg");
            }

            .nextPrevious:hover {
              background-image: url("../../assets/img/04-Buttons/button-next-page-hover.svg");
            }
          }
        }
      }

      .layout-content-left-display {
        width: 100%;
        height: calc(100% - 60px);
        border-radius: 56px;
        // background-color: #fff;
        position: relative;

        .teacher-mouse-move-area {
          border-radius: 58px;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          line-height: 1;
          // background: rgba(255, 255, 255, .3);
          z-index: 11;
        }
      }
    }
    .layout-content-right-lessonList {
      opacity: 0.96;
      background: #0e2e62;
      //   height: 100%;
      padding: 40px;
      flex: 1;
      .nav_lesson {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        background: #375075;
        border-radius: 9px;
        padding: 16px 5% 16px 6%;
        color: #fff;
        margin-bottom: 8px;
      }
      .red_color {
        font-family: Yuanti SC-Bold;
        color: #cd4c3f;
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadein {
  color: white;
  -webkit-animation: fadein 0.5s ease-in forwards;
  -moz-animation: fadein 0.5s ease-in forwards;
  animation: fadein 0.5s ease-in forwards;
}
</style>
