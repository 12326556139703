var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "canvasBox", staticClass: "customScrollbar-box" }, [
    _c("div", { staticClass: "wrap" }, [
      _c("canvas", {
        ref: "myCanvas",
        staticClass: "fl",
        class: { eraserStyle: _vm.drawStatus === 6 },
        attrs: {
          id: "canvas-1",
          width: _vm.canvasWidth,
          height: _vm.canvasHeight,
        },
        on: {
          mousedown: function ($event) {
            return _vm.handleMouseDown($event)
          },
          mousemove: function ($event) {
            return _vm.handleMouseMove($event)
          },
          mouseup: function ($event) {
            return _vm.handleMouseUp($event)
          },
          mouseleave: function ($event) {
            _vm.lock = false
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }