// 事件中心
import $bus from '@/utils/eventsBus'
import { deepCopy } from '@/utils/rejular'

const authTmp = JSON.parse(localStorage.userData);

let socketData = {
  type: '',
  from: authTmp.userSetting.userId,
  to: [],
  classroomid: '',
  data: {}
}

let sendMessage = function(data){
  socketData.data = {};
  for(let x in data){
    socketData.data[x] = data[x];
  }
  $bus.$emit('socket_sendMessage', deepCopy(socketData));
}

// 返回视频状态
export function sendMessage_10004003(data){
  // console.log(data)
  socketData.type = 10004003;
  sendMessage(data);
}
// 聊天消息发送
export function sendMessage_10005(data){
  // console.log(data)
  socketData.type = 10005;
  sendMessage(data);
}
// 系统消息发送
export function sendMessage_10005001(data){
  // console.log(data)
  socketData.type = 10005001;
  sendMessage(data);
}
// 白板中词语点击 定位使用
export function sendMessage_10006006(data){
  socketData.type = 10006006;
  sendMessage(data);
}

// level0 practiceone 
export function sendMessage_10006007(data){
  socketData.type = 10006007;
  sendMessage(data);
}
// level0 练习一选择题
export function sendMessage_10006008(data){
  socketData.type = 10006008;
  sendMessage(data);
}

// level0 intro phrase hover
export function sendMessage_10006009(data){
  socketData.type = 10006009;
  sendMessage(data);
}

// level0  练习二 选择题
export function sendMessage_100060011(data){
  socketData.type = 100060011;
  sendMessage(data);
}

// level0  练习二 填空题
export function sendMessage_100060012(data){
  socketData.type = 100060012;
  sendMessage(data);
}
// level0  练习二 填空题
export function sendMessage_1000600122(data){
  socketData.type = 1000600122;
  sendMessage(data);
}

// level0 练习二 submit
export function sendMessage_100060013(data){
  socketData.type = 100060013;
  sendMessage(data);
}

// level0  学生端连连看
export function sendMessage_100060014(data){
  socketData.type = 100060014;
  sendMessage(data);
}
